<div class="edit-vehicle-popup-wrapper">
    <div class="popup-header-wrapper">
        <div class="popup-header-container">
            <div class="header-icon">
                <img src="../../../../.././../../assets/svg/car-icon.svg" alt="users-plus">
            </div>
            @if(data.createVehicle) {
                <div class="popup-header-title">
                    <div class="header-title">Create Vehicle</div>
                </div>  
            } @else {
                <div class="popup-header-title">
                    <div class="header-title">Edit Vehicle</div>
                </div>
                <div class="right-btn" (click)="deactivateClick()">
                    <span>Deactivate</span>
                </div>
            }
        </div>
    </div>
    <form [formGroup]="vehicleForm" class="vehicle-form">
            <div class="form-caption">Account</div>
            <div class="row-1">
                <mat-form-field class="global-select-input">
                    @if (isCarrierLabelVisible()) {
                        <mat-label>Carrier</mat-label>
                    }
                    <mat-select formControlName="carrierId" panelClass="dropDwonMultipleFixClass">
                    @for (carrier of carriers; track $index) {
                        <mat-option id="allOption" [value]="carrier.id">{{carrier.name}}</mat-option>
                    }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="vehicleForm.get('carrierId')?.invalid">
                        <mat-error>
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Carrier is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row-2">
                <div class="block-input-icon-with-err">
                    <input type="text" class="form-control" id="vehicleUnit" formControlName="vehicleUnit" placeholder="Vehicle unit*">
                    <div class="input-err" *ngIf="vehicleForm.get('vehicleUnit')?.invalid && vehicleForm.get('vehicleUnit')?.touched">
                        <div *ngIf="vehicleForm.get('vehicleUnit').errors?.required">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Vehicle unit is required
                        </div>
                    </div>
                </div>
                <div class="block-input-icon-with-err">
                    <input type="text" class="form-control" id="vin" formControlName="vin" placeholder="VIN*">
                    <div class="input-err" *ngIf="vehicleForm.get('vin')?.invalid && vehicleForm.get('vin')?.touched">
                        <div *ngIf="vehicleForm.get('vin').errors?.required">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Vin is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-2">
                <mat-form-field class="global-select-input">
                    @if(isMakeTypeLabelVisible()){
                        <mat-label>Make</mat-label>
                    }
                    <mat-select formControlName="make" panelClass="dropDwonMultipleFixClass">
                      @for (trailerType of modelArr; track $index) {
                        <mat-option [value]="trailerType.code">{{trailerType.name}}</mat-option>
                      }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="vehicleForm.get('make')?.invalid">
                        <mat-error *ngIf="vehicleForm.get('make').errors?.required">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Make is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <div class="block-input-icon-with-err">
                    <input type="text" class="form-control" id="model" formControlName="model" placeholder="Model*">
                    <div class="input-err" *ngIf="vehicleForm.get('model')?.invalid && vehicleForm.get('model')?.touched">
                        <div *ngIf="vehicleForm.get('model').errors?.required">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Model is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-2">
                <mat-form-field class="global-select-input">
                    @if(isYearLabelVisible()){
                        <mat-label>Year</mat-label>
                    }
                    <mat-select formControlName="year" panelClass="dropDwonMultipleFixClass">
                      @for (year of yearsArr; track $index) {
                        <mat-option [value]="year.value">{{year.viewValue}}</mat-option>
                      }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="vehicleForm.get('year')?.invalid">
                        <mat-error *ngIf="vehicleForm.get('year').errors?.required">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Year is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="global-select-input">
                    @if(isFuelLabelVisible()){
                        <mat-label>Fuel</mat-label>
                    }
                    <mat-select formControlName="fuelCode" panelClass="dropDwonMultipleFixClass">
                      @for (fuelCode of fuelCodeArr; track $index) {
                        <mat-option [value]="fuelCode.code">{{fuelCode.name}}</mat-option>
                      }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="vehicleForm.get('fuelCode')?.invalid">
                        <mat-error *ngIf="vehicleForm.get('fuelCode').errors?.required">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Fuel is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-caption">License plate</div>
            <div class="row-3">
                <mat-select-country
                    class="select-country fix-row-3"
                    appearance="fill"
                    formControlName="country"
                    [itemsLoadSize]="50"
                    autocomplete="true">
                </mat-select-country>    
                <mat-error class="country-err" *ngIf="vehicleForm.get('country')?.invalid">
                    <mat-error>
                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Country is required
                    </mat-error>   
                    </mat-error>
                <mat-form-field class="global-select-input">
                    @if (isStateLabelVisible()) {
                        <mat-label>State/Province</mat-label>
                    }
                    <mat-select formControlName="stateProvinceCode" panelClass="dropDwonMultipleFixClass">
                    @for (state of states; track $index) {
                        <mat-option id="allOption" [value]="state.isoCode">{{state.name}}</mat-option>
                    }
                    </mat-select>
                    <mat-error class="input-err custom-err-select" *ngIf="vehicleForm.get('stateProvinceCode')?.invalid">
                        <mat-error *ngIf="vehicleForm.get('stateProvinceCode').errors?.required">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            State is required
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <div class="block-input-icon-with-err">
                    <input type="text" class="form-control" id="plateNumber" formControlName="plateNumber" placeholder="Plate number">
                    <div class="input-err" *ngIf="vehicleForm.get('plateNumber')?.invalid && vehicleForm.get('plateNumber')?.touched">
                        <div *ngIf="vehicleForm.get('plateNumber').errors?.required">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Plate Number is required
                        </div>
                    </div>
                </div>
            </div>
    </form> 
    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button class="global-btn btn-cancel" (click)="closeBtnClick()">
                <span class="btn-text">Cancel</span>
            </button>
            <button  class="global-btn btn-confirm" [ngClass]="{ 'disabled-btn': !vehicleForm.valid }" [disabled]="!vehicleForm.valid" (click)="okBtnClick()">
                <span class="btn-text">Confirm</span>
            </button>
        </div>
    </div>
</div>