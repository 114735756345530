import { Inject, Injectable } from '@angular/core';
// import { AUTH_API_URL } from '../app-injection-tokens';
import { Load } from '../../modules/main/pages/timeline/types/load';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Driver } from '../../interfaces/driver/driver';
import { Carrier } from '../../interfaces/carrier';
import { EmptyMilesRequest } from '../../interfaces/loads/emptyMilesRequest';
import { EmptyMiles } from '../../interfaces/loads/empty-miles';
import { AUTH_API_URL } from '../app-injection-tokens';
import { TotalByReq } from '../../interfaces/loads/total-by-req';
import { Split } from '../../interfaces/loads/split';
import { Note } from '../../interfaces/loads/note';

@Injectable({
  providedIn: 'root',
})
export class LoadsService {
  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }
  // apiUrl: string = 'https://apidev.traxsphere.com';
  // getLoadsByRange(dateBgn: string, dateEnd: string): Observable<Load[]> | null {
  // if (!this.authService.isAuthenticated()) {
  //   this.router.navigate(['/login'])
  //   return null
  // }
  //   const httpParams = new HttpParams().set('LoadGuid', 'ALL').set('DateBgn', dateBgn).set('dateEnd', dateEnd)
  //   return this.http.get<Load[]>(this.apiUrl + 'api/rtmLoad/Loads', {params: httpParams})
  // }

  createLoad(load: any): Observable<any> {
    return this.http.post<Load>(this.apiUrl + '/api/loads/add', load);
  }

  createPartialLoad(load: any, parentId: number): Observable<any> {
    return this.http.post<Load>(
      this.apiUrl + `/api/loads/add/partial/${parentId}`,
      load
    );
  }

  createSplit(split: Split): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/loads/split', split);
  }

  createDriver(data: any): Observable<any> {
    // console.log(data);

    let payload: any = {
      id: 0,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone.number,
      dispatcherId: data.dispatcherId,
      carrierId: data.carrierId,
      vehicleId: data.vehicleId,
      trailerId: data.trailerId,
      license: data.driverLicenseNumber,
      licenseCountryCode: data.driverLicenseCountryCode.alpha2Code,
      licenseStateCode: data.driverLicenseStateCode,
      fullAddress: data.fullAddress,
      street: data.street,
      city: data.city,
      state: data.stateCode,
      zipCode: data.zipCode,
      countryCode: data.countryCode.alpha2Code,
      addressLatitude: data.latitude,
      addressLongitude: data.longitude,
      files: data.files,
      password: data.password,
      ...(('coDriver' in data) && {
        coDriver: {
          firstName: data.coDriver.firstName,
          lastName: data.coDriver.lastName,
          email: data.coDriver.email,
          phone: data.coDriver.phone.number,
          license: data.coDriver.driverLicenseNumber,
          licenseStateCode: data.coDriver.driverLicenseStateCode,
          licenseCountryCode: data.coDriver.driverLicenseCountryCode.alpha2Code,
          fullAddress: data.coDriver.fullAddress,
          street: data.coDriver.street,
          countryCode: data.coDriver.countryCode.alpha2Code,
          state: data.coDriver.stateCode,
          city: data.coDriver.city,
          zipCode: data.coDriver.zipCode,
          addressLatitude: data.coDriver.latitude,
          addressLongitude: data.coDriver.longitude,
          password: data.coDriver.password,
        }
      })
    };

    if (data.newCarrierName) payload.carrierName = data.newCarrierName;
    if (data.newTrailerUnit) payload.trailerUnit = data.newTrailerUnit;
    if (data.newVehicleUnit) payload.vehicleUnit = data.newVehicleUnit;
    return this.http.post<any>(this.apiUrl + '/api/drivers', payload);
  }

  verifyEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + `/api/drivers/verify_email/${email}`);
  }

  updateLoad(loadId: number, updatedLoad: Load, areLoadMilesChanged : boolean): Promise<any> {
    updatedLoad.areLoadMilesChanged = areLoadMilesChanged;
    return this.http.put<Load>(
      this.apiUrl + `/api/loads/${loadId}`,
      updatedLoad
    ).toPromise();
  }

  changeStatusByLoadId(loadId: any, status: string): Observable<string> {
    return this.http.patch<string>(`${this.apiUrl}/api/loads/${loadId}/status`,
      { status: status },
      { responseType: 'text' as 'json' });
  }

  updateTarget(targetData: Array<any>, driverID: number): Observable<any> {
    let payload: Array<any> = new Array<any>();

    if (targetData.length === 0) payload = [];
    else {
      payload = targetData.map((m) => ({
        driverId: driverID,
        targetValue: m.targetValue,
        recalculatedTargetValue: m.recalculatedTargetValue,
        targetType: m.targetType,
        targetIntervalType: m.targetIntervalType,
      }));
    }

    return this.http.put<Load>(this.apiUrl + `/api/drivers/target`, payload);
  }

  updateCarrier(data: any, carrierID: number): Observable<any> {
    let payload = {
      name: data.name,
      mcNumber: data.mcNumber,
      dotNumber: data.dotNumber,
      street: data.street,
      countryCode: data.country.alpha2Code,
      stateProvinceCode: data.stateProvinceCode,
      zipCode: data.zipCode,
      timezoneCode: data.timezoneCode,
      files: data.files,
    };
    return this.http.put<Carrier>(
      this.apiUrl + `/api/carriers/${carrierID}`,
      payload
    );
  }

  createCarrier(data: any): Observable<any> {
    let payload = {
      name: data.name,
      mcNumber: data.mcNumber,
      dotNumber: data.dotNumber,
      street: data.street,
      country: data.country.alpha2Code,
      state: data.stateProvinceCode,
      zipcode: data.zipCode,
      timezone: data.timezoneCode,
      files: data.files
    }
    return this.http.post<any>(this.apiUrl + '/api/carriers', payload);
  }


  deleteCarrier(carrierID: number): Observable<any> {
    return this.http.delete(this.apiUrl + '/api/carriers/' + carrierID);
  }

  updateDriver(data: any, driverID: number): Observable<any> {
    // console.log(data);

    let payload: any = {
      // id: driverID,
      carrierId: data.carrierId,
      dispatcherId: data.dispatcherId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone.number,
      vehicleId: data.vehicleId,
      trailerId: data.trailerId,
      licenseCountryCode: data.driverLicenseCountryCode.alpha2Code,
      licenseStateCode: data.driverLicenseStateCode,
      license: data.driverLicenseNumber,
      street: data.street,
      countryCode: data.countryCode.alpha2Code,
      state: data.stateCode,
      city: data.city,
      fullAddress: data.fullAddress,
      zipcode: data.zipCode,
      addressLatitude: data.latitude,
      addressLongitude: data.longitude,
      files: data.files,
      ...(('coDriver' in data) && {
        coDriver: {
          id: data.coDriver.id || 0,
          firstName: data.coDriver.firstName,
          lastName: data.coDriver.lastName,
          email: data.coDriver.email,
          phone: data.coDriver.phone.number,
          license: data.coDriver.driverLicenseNumber,
          licenseStateCode: data.coDriver.driverLicenseStateCode,
          licenseCountryCode: data.coDriver.driverLicenseCountryCode.alpha2Code,
          fullAddress: data.coDriver.fullAddress,
          street: data.coDriver.street,
          countryCode: data.coDriver.countryCode.alpha2Code,
          state: data.coDriver.stateCode,
          city: data.coDriver.city,
          zipCode: data.coDriver.zipCode,
          addressLatitude: data.coDriver.latitude,
          addressLongitude: data.coDriver.longitude,
          password: data.coDriver.password,
        }
      }),
      ...(!('coDriver' in data) && { coDriver: null })
    };

    if (data.newCarrierName) payload.carrierName = data.newCarrierName;
    if (data.newTrailerUnit) payload.trailerUnit = data.newTrailerUnit;
    if (data.newVehicleUnit) payload.vehicleUnit = data.newVehicleUnit;

    return this.http.put<Driver>(
      this.apiUrl + `/api/drivers/${driverID}`,
      payload
    );
  }

  updateTrailer(data: any, trailerID: number): Observable<any> {
    let payload = {
      carrierId: data.carrierId,
      trailerUnit: data.trailerUnit,
      vin: data.vin,
      make: data.make,
      year: data.year,
      trailerTypeCode: data.trailerTypeCode,
      ownershipCode: data.ownershipCode,
      countryCode: data.country.alpha2Code,
      stateProvinceCode: data.stateProvinceCode.isoCode,
      plateNumber: data.plateNumber,
      plateExpirationDate: data.plateExpirationDate,
    };
    return this.http.put<any>(
      this.apiUrl + `/api/trailers/${trailerID}`,
      payload
    );
  }

  createTrailer(data: any): Observable<any> {
    let payload = {
      carrierId: data.carrierId,
      trailerUnit: data.trailerUnit,
      vin: data.vin,
      make: data.make,
      year: data.year,
      trailerTypeCode: data.trailerTypeCode,
      ownershipCode: data.ownershipCode,
      countryCode: data.country.alpha2Code,
      stateProvinceCode: data.stateProvinceCode.isoCode,
      plateNumber: data.plateNumber,
      plateExpirationDate: data.plateExpirationDate,
    };
    return this.http.post<any>(this.apiUrl + `/api/trailers`,payload);
  }

  updateVehicle(data: any, vehicleID: number): Observable<any> {
    let payload = {
      carrierId: data.carrierId,
      vehicleUnit: data.vehicleUnit,
      vin: data.vin,
      make: data.make,
      model: data.year,
      year: data.year,
      fuelCode: data.fuelCode,
      countryCode: data.country.alpha2Code,
      stateProvinceCode: data.stateProvinceCode,
      plateNumber: data.plateNumber,
    };
    return this.http.put<any>(
      this.apiUrl + `/api/vehicles/${vehicleID}`,
      payload
    );
  }

  createVehicle(data: any): Observable<any> {
    let payload = {
      carrierId: data.carrierId,
      vehicleUnit: data.vehicleUnit,
      vin: data.vin,
      make: data.make,
      model: data.year,
      year: data.year,
      fuelCode: data.fuelCode,
      countryCode: data.country.alpha2Code,
      stateProvinceCode: data.stateProvinceCode,
      plateNumber: data.plateNumber,
    };
    return this.http.post<any>(this.apiUrl + `/api/vehicles`,payload);
  }

  deleteVehicle(vehicleID: number): Observable<any> {
    return this.http.delete(this.apiUrl + '/api/vehicles/' + vehicleID);
  }

  createNote(note: any): Observable<any> {
    let payload = {
      id: 0,
      driverId: note.driverId,
      reason: note.reason,
      otherReason: note.otherReason,
      start: this.parseStartDate(note.start),
      end: this.parseEndDate(note.end),
      fullAddress: note.fullAddress
    }
    return this.http.post<any>(this.apiUrl + '/api/notes/add', payload);
  }

  updateNote(noteId: number, note: any) {
    let payload = {
      id: 0,
      driverId: note.driverId,
      reason: note.reason,
      otherReason: note.otherReason,
      start: this.parseStartDate(note.start),
      end: this.parseEndDate(note.end),
      fullAddress: note.fullAddress
    }

    return this.http.put<any>(this.apiUrl + `/api/notes/${noteId}`, payload);
  }

  deleteNote(noteId: number) {
    return this.http.delete<any>(this.apiUrl + `/api/notes/${noteId}`)
  }

  createDriverNote(note: any, driverID: number): Promise<any> {
    return this.http
      .patch<any>(this.apiUrl + `/api/drivers/note/${driverID}`, note)
      .toPromise();
  }

  getTotalBy(obj: any): Observable<any> {
    return this.http
      .post<any>(this.apiUrl + '/api/loads/get_total_by', obj)
  }

  getAllLoads(): Promise<any> {
    return this.http.get<Load[]>(this.apiUrl + '/api/loads').toPromise();
  }

  getLoadsAndNotesForTmln(yearMonth: any): Promise<any> {
    return this.http.get<any>(this.apiUrl + `/api/tmlne?yearMonth=${yearMonth}`).toPromise();
  }

  getAllNotes(): Promise<any> {
    return this.http.get<Note[]>(this.apiUrl + '/api/notes').toPromise()
  }

  getLoadById(loadId: number): Promise<any> {
    return this.http
      .get<Load>(this.apiUrl + `/api/loads/${loadId}`)
      .toPromise();
  }

  checkLoadInfoOrder(load: Load): Promise<any> {
    return this.http.post<Load>(this.apiUrl + '/api/loads/check_load_info_order', load).toPromise()
  }

  getUserById(userID: number): Promise<any> {
    return this.http.get<any>(this.apiUrl + `/api/users/${userID}`).toPromise();
  }

  getDriverById(driverID: number): Promise<any> {
    return this.http
      .get<Driver>(this.apiUrl + `/api/drivers/${driverID}`)
      .toPromise();
  }

  getCarrierById(carrierID: number): Promise<any> {
    return this.http
      .get<Carrier>(this.apiUrl + `/api/carriers/${carrierID}`)
      .toPromise();
  }

  getTrailerById(trailerId: number): Promise<any> {
    return this.http
      .get<any>(this.apiUrl + `/api/trailers/${trailerId}`)
      .toPromise();
  }

  deleteTrailer(trailerID: number): Observable<any> {
    return this.http.delete(this.apiUrl + '/api/trailers/' + trailerID);
  }

  getVehicleById(vehicleId: number): Promise<any> {
    return this.http
      .get<any>(this.apiUrl + `/api/vehicles/${vehicleId}`)
      .toPromise();
  }

  getAllCarriers(): Promise<any> {
    return this.http.get<Carrier[]>(this.apiUrl + '/api/carriers').toPromise();
  }

  getAllBrokers(): Promise<any> {
    return this.http.get<Carrier[]>(this.apiUrl + '/api/brokers').toPromise();
  }

  getAllDispatchers(): Promise<any> {
    return this.http
      .get<any[]>(this.apiUrl + '/api/users/dispatchers/all')
      .toPromise();
  }

  getAllDrivers(showSupervisorDrivers: boolean = false): Promise<any> {
    const url = showSupervisorDrivers
      ? '/api/drivers/all_simple_view?show_supervisor_drivers=true'
      : '/api/drivers/all_simple_view';
    return this.http.get<Driver[]>(this.apiUrl + url).toPromise();
  }

  getAllTrailers(): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/trailers').toPromise();
  }

  getAllVehicles(): Promise<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/vehicles').toPromise();
  }

  getAllDataReference(): Promise<any> {
    return this.http
      .get<any[]>(this.apiUrl + '/api/reference_data/all')
      .toPromise();
  }

  setAndGetEmptyMiles(emptyMilesRequest: EmptyMilesRequest): Promise<any> {
    return this.http
      .post<EmptyMiles>(
        this.apiUrl + `/api/loads/get_empty_miles`,
        emptyMilesRequest
      )
      .toPromise();
  }

  getReference(): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/api/reference_data/all`);
  }

  public parseStartDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let localISOTime = `${year}-${month}-${day}T00:00:00Z`;

    return localISOTime
  }

  public parseEndDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let localISOTime = `${year}-${month}-${day}T23:59:59Z`;

    return localISOTime
  }
}
