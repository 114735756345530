<div class="broker-information">
  <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
    <div style="display: flex; align-items: center; gap: 16px;">
      <div class="container-icon">
        <mat-icon><img src="../../../../../../../../../../../assets/svg/directions-car-img.svg" alt=""></mat-icon>
      </div>
      <div>
        <strong class="view-delivery-strong">{{'Broker Information '}}</strong>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
      <div style="width: 48%" class="block-inputs">
        <input type="text" formControlName="company" [matAutocomplete]="auto" placeholder="Select Company"
          #inputAutoComplete (input)="brokerInput($event)">
        <mat-autocomplete #auto="matAutocomplete"
          (optionSelected)="addInformationAboutBrokerByName($event.option.value)">
          @for (broker of filteredBroker| async; track brokers) {
          <mat-option [value]="broker">{{broker.businessName}}</mat-option>
          }
        </mat-autocomplete>
      </div>
      <div style="width: 48%" class="block-inputs">
        <input formControlName="name" type="text" placeholder="Name" />
      </div>
    </div>
    <div class="input-container">
      <div style="width: 100%;" class="block-input-icon">
        <mat-icon><img src="../../../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
        <input formControlName="fullAddress" [id]="'google-search' + 'eb'" required
          placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text">
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;  margin-bottom: 10px;">
      <div style="width: 48%;" class="block-inputs">
        <ngx-intl-tel-input style="width: 100%;" [preferredCountries]="[CountryISO.UnitedStates]"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
          [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true" [maxLength]="15"
          [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
      </div>
      <div style="width: 48%;" class="block-input-icon">
        <mat-icon matPrefix><img src="../../../../../../../../assets/svg/email-img.svg"></mat-icon>
        <input style="margin: 3px 0 3px 5px;" formControlName="email" placeholder="Email" type="text">
      </div>
    </div>
  </form>
  <div class="footer-btn">
    <button class="cancel-btn" (click)="cancel()">Cancel</button>
    <button class="save-btn" (click)="confirm()">Confirm</button>
  </div>
</div>