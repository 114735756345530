<div class="main-container">
  <mat-tab-group [animationDuration]="'500'" class="tabs" (selectedTabChange)="changeTab($event)"
    [selectedIndex]="index" animationDuration="4ms" [dynamicHeight]="true" mat-stretch-tabs="false"
    mat-align-tabs="start">
    <mat-tab origin="0" [label]="result.partial ? 'Create Partial Load' : 'Create Load'">
      <ng-template matTabContent>
        <app-create-modal-load class="modals" [dispathers]="dispathers" [carriers]="carriers" [drivers]="drivers"
          [start]="start" [end]="end" [loadInfo]="loadInfo" [partial]="partial" (isFormValid)="isFormValidLoad($event)">
        </app-create-modal-load>
      </ng-template>
    </mat-tab>
    <mat-tab label="Create Tonu">
      <ng-template matTabContent>
        <app-create-modal-tonu class="modals" [dispathers]="dispathers" [carriers]="carriers" [drivers]="drivers"
          [start]="start" [end]="end" [loadInfo]="loadInfo" (isFormValid)="isFormValidTonu($event)">
        </app-create-modal-tonu>
      </ng-template>
    </mat-tab>
    <mat-tab label="Create Note">
      <ng-template matTabContent>
        <app-create-modal-note style="display: block; width: 640px!important;" [loadInfo]="loadInfo" [start]="start"
          [end]="end" [drivers]="drivers" (isFormValid)="isFormValidNote($event)"></app-create-modal-note>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="save-container">
    <button style="width: 135px; border-radius: 4px;" class="save-btn" (click)="saveValue(index)"[disabled]="disableMethod()" [ngClass]="{ 'disabled-btn': disableMethod() }">
      <svg-icon *ngIf="result.partial" src="assets/svg/car-img.svg"></svg-icon>
      {{'Save' + " " + nameTab}}
    </button>
    <button class="close-btn" (click)="closeModal()"><mat-icon
        style="cursor: pointer; width: 24px; height: 24px; color: #667085;">close</mat-icon></button>
  </div>
</div>