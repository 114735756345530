import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,

} from '@angular/cdk/drag-drop';
import { GeometryService } from '../../../../../../../../../services/geometry/geometry.service';
import { LoadsService } from '../../../../../../../../../services/loads/loads.service';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as L from 'leaflet';
import { decode } from '@mapbox/polyline';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewDeliveryInfoComponent } from './view-delivery-info/view-delivery-info.component';
import { SharedService } from '../../../../../../../../../services/sharedServices/shared.service';
import { BrokerService } from '../../../../../../../../../services/brokers/broker.service';
import { Broker } from '../../../../../../../../../interfaces/loads/broker';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { Load } from '../../../../../types/load';
import { GetCarrierIdByNameService } from '../../../../../../../../../services/serviceForTransformFields/getCarrierIdByName.service';
import { GetDispatcherIdByNameService } from '../../../../../../../../../services/serviceForTransformFields/getDispatcherIdByName.service';
import { GetDriverNameByIdService } from '../../../../../../../../../services/serviceForTransformFields/getDriverNameById.service';
import { GetPhoneNumberAndDateInFormArrayService } from '../../../../../../../../../services/serviceForTransformFields/getPhoneNumberAndDateInFormArray.service';
import { CreateModalAllComponent } from '../../../../create-modal-all/create-modal-all.component';
import { ViewEditLoadComponent } from '../../view-edit-load.component';
import { EmptyMilesRequest } from '../../../../../../../../../interfaces/loads/emptyMilesRequest';
import { EditBrokerInfoComponent } from '../../../../../components/edit-broker-info/edit-broker-info.component';
import { TimeLineCalendarComponent } from '../../../../../time-line-calendar/time-line-calendar.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActionsFee } from '../../../../../../../../../enum/actionsFee';
import { DialogService } from '../../../../../../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-edit-load',
  templateUrl: './edit-load.component.html',
  styleUrls: ['./edit-load.component.scss'],
})
export class EditLoadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabledForm!: { value: boolean };
  @Input() inputIdMap: any;
  @Input() selectedLoad: any;
  @Input() dispathers: any;
  @Input() carriers: any[] = [];
  @Input() drivers: any[] = [];

  @Output() formStatusChanged = new EventEmitter<boolean>();

  @ViewChild('milesInput') milesInput!: ElementRef;
  @ViewChild('loadInput') loadInput!: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) selectFeeType!: MatAutocompleteTrigger;

  form!: FormGroup;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  pIndex: any;
  openType: boolean = false;
  dIndex: any;
  partialId: any;
  map: any;
  hours!: number;
  minutes!: number;
  rateMile!: number;
  pointsForDirection: string[] = [];
  fileLabels: any;
  private initialLoadedMiles!: number;
  distanceAndDuration: any[] = [];

  actionsFee: any;
  actionFeeKeys: any;

  partialPickUp: any[] = [];
  partialDelivery: any[] = [];

  load!: Load;

  brokers!: Broker[];
  filteredBroker: Observable<Broker[]> | undefined;

  stops!: any[] | any[];
  currentPickupDelivery!: any | any;
  idx: number = 0;
  type!: 'pickup' | 'delivery';

  markers: any[] = [];

  status: any[] = [];
  milesInputDisable: boolean = false;
  openLocationModal: boolean = false;
  emptyMilesRequest = {} as EmptyMilesRequest;
  emptyMilesOptions = ['Last Delivery', 'Location', 'Manually', 'Driver Home'];
  dropdownOpen: boolean = false;
  typeOfEmptyMiles: string = '';

  initialFormValues: any;
  inputPickUp: string = 'pe'
  inputDelivery: string = 'de'
  inputBroker: string = 'be'
  hasChange: boolean = false;
  feeType: any[] = [];
  itemsSubscription: Subscription = new Subscription();
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TimeLineCalendarComponent>,
    private loadService: LoadsService,
    private brokerService: BrokerService,
    private geometryService: GeometryService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private getDriverNameById: GetDriverNameByIdService,
    private getCarrierIdByName: GetCarrierIdByNameService,
    private getDispatcherIdByName: GetDispatcherIdByNameService,
    private getPhoneNumberAndDateInFormArray: GetPhoneNumberAndDateInFormArrayService,
    private dialogService: DialogService,
  ) { }

  async ngOnInit() {
    this.dialogService.dialogClosed$.subscribe((data) => {
      this.selectedLoad = data
      this.initForm();
      setTimeout(() => this.initMap())
    });

    this.actionsFee = ActionsFee;
    this.actionFeeKeys = Object.keys(ActionsFee);
    this.initForm();
    this.getReference();
    await this.getAllBrokers();

    if (this.selectedLoad?.partial?.length > 0 || this.selectedLoad?.partial) {
      this.selectedLoad?.partial?.forEach((value: any) => {
        value.pickup.forEach((pick: any) => pick.partial = true)
        value.delivery.forEach((del: any) => del.partial = true)
        this.partialPickUp.push(...value.pickup);
        this.partialDelivery.push(...value.delivery);
      });
      this.inputPickUp = 'ppe'
      this.inputDelivery = 'pde'
      this.inputBroker = 'pbe'
    }
    this.typeOfEmptyMiles = this.selectedLoad.emptyMiles.type
    this.sortPickupsAndDelivery();
    this.changeSelectedTab(0, 'pickup');
    this.changeSelectedTab(0, 'delivery');
    if (this.selectedLoad?.partial) {
      this.changeSelectedTab(0, 'partial');
    }
    setTimeout(() => this.createAddress());
    this.filteredBroker = this.form.get('company')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.businessName;
        return name ? this._filterBroker(name as string) : this.brokers.slice();
      })
    );
    this.updateFormState(this.disabledForm.value);
    setTimeout(() => this.initMap());
    this.form?.valueChanges.subscribe(() => {
      this.formStatusChanged.emit(this.form.dirty);
    });
    this.adjustWidthOnLoad();
    this.initialFormValues = this.form.getRawValue();

    if (this.selectedLoad.emptyMiles.type === '' && !this.selectedLoad.parentId) {
      this.itemsSubscription = this.geometryService.emptyMilesItem.subscribe((item) => {
        if (item) {
          setTimeout(() => {
            this.selectLastDelivery('lastDelivery');
            setTimeout(() => this.initMap());
          });
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabledForm']) {
      this.updateFormState(this.disabledForm.value);
    }

    if (this.selectedLoad?.parentId) {
      this.form.get('carrier')?.disable();
      this.form.get('driver')?.disable();
      this.form.get('dispatcher')?.disable();
    }
  }

  updateFormState(disabled: boolean) {
    if (disabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
      this.form.get('partialPrice')?.disable();
      this.form.get('totalPrice')?.disable();
    }
  }

  displayFn(broker: Broker): string {
    return broker && broker.businessName ? broker.businessName : '';
  }

  private _filterBroker(name: string): Broker[] {
    const filterValue = name.toLowerCase();
    return this.brokers.filter((broker) =>
      broker.businessName?.toLowerCase().includes(filterValue)
    );
  }

  async addInformationAboutBrokerByName(broker: any) {
    for (let i = 0; i < this.brokers.length; i++) {
      if (this.brokers[i].businessName === broker) {
        this.form.get('email')?.setValue(this.brokers[i].email);
        this.form.get('name')?.setValue(this.brokers[i].fullName);
        this.form.get('phone')?.setValue(this.brokers[i].phone);
        this.form.get('brokerId')?.setValue(this.brokers[i].id);
        this.form
          .get('fullAddress')
          ?.setValue(this.makeFullAddress(this.brokers[i]));
      }
    }
  }

  sortPickupsAndDelivery() {
    this.stops = [
      ...this.pickups?.controls.map((value) => value.value),
      ...this.partialPickUp,
      ...this.delivery?.controls.map((value) => value.value),
      ...this.partialDelivery,
    ];

    this.stops.sort((a: any, b: any) => {
      return a.infoOrder - b.infoOrder;
    });
  }

  makeFullAddress(broker: Broker) {
    if (broker) {
      let fullAddress = broker?.street
        ? broker?.street
        : '' + ',' + broker?.city
          ? broker?.city
          : '' + ',' + broker?.stateCode
            ? broker?.stateCode
            : '' + ',' + broker?.zipCode
              ? broker?.zipCode
              : '' + ',' + broker?.countryCode
                ? broker?.countryCode
                : '';
      return fullAddress;
    } else {
      return;
    }
  }

  changeSelectedTab(index: any, key: string) {
    if (key === 'pickup') {
      this.pIndex = index;
      setTimeout(() => {
        this.geometryService.findAddress(
          'google-search' + 'p' + this.pIndex,
          this.pickups.controls[this.dIndex]
        );
      });
    } else if (key === 'delivery') {
      this.dIndex = index;
      setTimeout(() => {
        this.geometryService.findAddress(
          'google-search' + 'd' + this.dIndex,
          this.delivery.controls[this.dIndex]
        );
      });
    } else {
      this.partialId = index;
    }
    setTimeout(() => this.createAddress());
  }

  initForm() {
    if (this.selectedLoad) {
      this.form = this.fb.group({
        customLoadId: new FormControl(
          this.selectedLoad?.customLoadId ? this.selectedLoad?.customLoadId : '',
          Validators.required
        ),
        loadId: new FormControl(
          this.selectedLoad?.loadId ? this.selectedLoad?.loadId : '',
          Validators.required
        ),
        parentId: new FormControl(
          this.selectedLoad?.parentId ? this.selectedLoad?.parentId : null,
          Validators.required
        ),
        carrier: new FormControl(
          this.selectedLoad?.carrierName ? this.selectedLoad?.carrierName : '',
          Validators.required
        ),
        driver: new FormControl(
          this.selectedLoad?.driverName ? this.selectedLoad?.driverName : '',
          Validators.required
        ),
        driverId: new FormControl(
          this.selectedLoad?.driverId ? this.selectedLoad?.driverId : '',
          Validators.required
        ),
        dispatcher: new FormControl(
          this.selectedLoad?.dispatcher?.name
            ? this.selectedLoad?.dispatcher?.name
            : '',
          Validators.required
        ),
        brokerId: new FormControl(this.selectedLoad?.broker?.id ? this.selectedLoad?.broker?.id : null),
        phone: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.phone : ''
        ),
        company: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.businessName : ''
        ),
        fullAddress: new FormControl(
          this.makeFullAddress(this.selectedLoad?.broker)
        ),
        street: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.street : ''
        ),
        city: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.city : ''
        ),
        stateCode: new FormControl(
          this.selectedLoad?.broker
            ? this.selectedLoad?.broker?.stateProvinceCode
            : ''
        ),
        status: new FormControl(
          this.selectedLoad?.status ? this.selectedLoad?.status : ''
        ),
        zipCode: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.zipCode : ''
        ),
        countryCode: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.countryCode : ''
        ),
        name: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.fullName : ''
        ),
        miles: new FormControl(
          this.selectedLoad?.emptyMiles?.distance ? this.convertNumber(this.selectedLoad?.emptyMiles?.distance) : 0
        ),
        longitude: new FormControl(
          this.selectedLoad?.emptyMiles.longitude
            ? this.selectedLoad?.emptyMiles.longitude
            : 0
        ),
        latitude: new FormControl(
          this.selectedLoad?.emptyMiles.latitude
            ? this.selectedLoad?.emptyMiles.latitude
            : 0
        ),
        loadMiles: new FormControl(
          this.selectedLoad?.loadMiles
            ? this.selectedLoad?.loadMiles
            : 0
        ),
        price: new FormControl(
          this.selectedLoad?.price ? this.selectedLoad?.price : 0
        ),

        partialPrice: new FormControl(this.selectedLoad?.partialPrice ? this.selectedLoad?.partialPrice : 0),

        totalPrice: new FormControl(this.selectedLoad.totalPrice ? this.selectedLoad.totalPrice : 0),
        email: new FormControl(
          this.selectedLoad?.broker ? this.selectedLoad?.broker?.email : ''
        ),
        pickups: this.fb.array(
          this.selectedLoad?.pickup.map((data: any) => this.createPickup(data))
        ),
        delivery: this.fb.array(
          this.selectedLoad?.delivery.map((data: any) =>
            this.createDelivery(data)
          )
        ),
        fee: this.fb.array(
          this.selectedLoad?.fees.map((data: any) => this.createFee(data))
        ),
        partialLoad: this.fb.array(
          this.selectedLoad?.partial?.map((data: any) =>
            this.createPartial(data)
          ) || []
        ),
      });
      this.initialLoadedMiles = this.form.get('loadMiles')?.value;
    }
  }

  get fee(): FormArray {
    return this.form?.get('fee') as FormArray;
  }

  get pickups(): FormArray {
    return this.form?.get('pickups') as FormArray;
  }

  get delivery(): FormArray {
    return this.form?.get('delivery') as FormArray;
  }

  get partialLoad(): FormArray {
    return this.form?.get('partialLoad') as FormArray;
  }

  createPickup(pickupData?: any): FormGroup {
    return this.fb.group({
      id: [pickupData?.id || 0],
      businessName: [pickupData?.businessName || ''],
      fullAddress: [pickupData?.fullAddress || '', Validators.required],
      street: [pickupData?.street || ''],
      city: [pickupData?.city || ''],
      stateCode: [pickupData?.stateCode || ''],
      zipCode: [pickupData?.zipCode || ''],
      countryCode: [pickupData?.countryCode || ''],
      dateScheduledFrom: [
        this.normalizeDate(pickupData?.dateScheduledFrom)
      ],
      pickupTime: [
        pickupData ? this.getTimeFormDate(pickupData?.dateScheduledFrom) : '',
      ],
      fullName: [pickupData?.fullName || ''],
      phone: [pickupData?.phone || ''],
      notes: [pickupData?.notes || ''],
      latitude: [pickupData?.latitude || ''],
      infoType: [pickupData?.infoType || ''],
      infoOrder: [pickupData?.infoOrder || 0],
      longitude: [pickupData?.longitude || ''],
      email: [pickupData?.email || ''],
      dateScheduledTo: [pickupData?.dateScheduledTo ? this.getTimeFormDate(pickupData?.dateScheduledTo) : ''],
      distance: [],
      duration: [],
    });
  }

  private normalizeDate(date: string | Date): Date {
    if (!date) return new Date();

    const localDate = new Date(date);

    const utcDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(),
      localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds());

    return utcDate;
  }

  updatePickupArray() {
    this.form.setControl('pickup', this.createPickup());
  }

  createDelivery(deliveryData?: any): FormGroup {
    return this.fb.group({
      id: [deliveryData?.id || 0],
      businessName: [deliveryData?.businessName || ''],
      fullAddress: [deliveryData?.fullAddress || '', Validators.required],
      street: [deliveryData?.street || ''],
      city: [deliveryData?.city || ''],
      stateCode: [deliveryData?.stateCode || ''],
      zipCode: [deliveryData?.zipCode || ''],
      countryCode: [deliveryData?.countryCode || ''],
      dateScheduledFrom: [
        this.normalizeDate(deliveryData?.dateScheduledFrom)
      ],
      deliveryTime: [
        deliveryData
          ? this.getTimeFormDate(deliveryData?.dateScheduledFrom)
          : '',
      ],
      fullName: [deliveryData?.fullName || ''],
      phone: [deliveryData?.phone || ''],
      notes: [deliveryData?.notes || ''],
      latitude: [deliveryData?.latitude || ''],
      longitude: [deliveryData?.longitude || ''],
      infoType: [deliveryData?.infoType || ''],
      infoOrder: [deliveryData?.infoOrder || ''],
      email: [deliveryData?.email || ''],
      dateScheduledTo: [deliveryData?.dateScheduledTo ? this.getTimeFormDate(deliveryData?.dateScheduledTo) : ''],
      distance: [],
      duration: [],
    });
  }

  createPartial(partialData?: any): FormGroup {
    return this.fb.group({
      partialLoadId: [partialData?.customLoadId || ''],
      partialDateFrom: [this.formatDate(this.normalizeDate(partialData?.pickup?.[0]?.dateScheduledFrom).toString()) || ''],
      partialDateTo: [this.formatDate(this.normalizeDate(partialData?.delivery?.[0]?.dateScheduledFrom).toString()) || ''],
      partialRatePerMi: [partialData?.rateMile || ''],
      partialPrice: [partialData?.price || 0],
      partialTotalMi: [this.convertNumber(partialData?.totalMi) || '0'],
      partialInfoOrder: ['']
    });
  }

  getPickupNumber(index: number): number {
    return this.stops
      .slice(0, index + 1)
      .filter((item) => item.infoType === 'PICKUP').length;
  }

  getDeliveryNumber(index: number): number {
    return this.stops
      .slice(0, index + 1)
      .filter((item) => item.infoType === 'DELIVERY').length;
  }

  async getAllBrokers() {
    let response = await this.brokerService.getAllBrokers();
    this.brokers = response;
  }

  viewInfoAboutDelivery() {
    let delivery = this.form.get('delivery') as FormArray;
    const dialogRef = this.dialog.open(ViewDeliveryInfoComponent, {
      data: delivery,
      width: '700px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateFormArray(result);
      }
    });
  }


  createPartialLoad() {
    const dialogRef = this.dialog.open(CreateModalAllComponent, {
      data: {
        index: 0,
        drivers: this.drivers,
        carriers: this.carriers,
        load: this.selectedLoad,
        dispatcers: this.dispathers,
        startDate: new Date(),
        endDate: new Date(),
        partial: true,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (!this.selectedLoad?.partial) {
          this.selectedLoad.partial = [];
        }
        this.selectedLoad = response

        if (this.selectedLoad?.partial?.length > 0 || this.selectedLoad?.partial) {
          this.selectedLoad?.partial?.forEach((value: any) => {
            value.pickup.forEach((pick: any) => pick.partial = true)
            value.delivery.forEach((del: any) => del.partial = true)
            this.partialPickUp.push(...value.pickup);
            this.partialDelivery.push(...value.delivery);
          });
        }
        this.initForm();
        this.sortPickupsAndDelivery();
        this.form.updateValueAndValidity();
        this.changeSelectedTab(0, 'partial');
        setTimeout(() => this.initMap());
      }
    });
  }

  updateFormArray(result: any) {
    const deliveryArray = this.form.get('delivery') as FormArray;
    deliveryArray.clear();
    result.forEach((item: any) => {
      deliveryArray.push(this.fb.group(item));
    });
  }

  updatePartialLoadForm() {
    let partialPrice: any[] = [];
    let totalPriceMainLoad = this.form.get('price')?.value
    const partialArray = this.form.get('partialLoad') as FormArray;
    partialArray.clear();
    this.selectedLoad?.partial.forEach((data: any) => {
      partialArray.push(this.createPartial(data));
      partialPrice.push(data.price)
    });
    let totalPartialPrice = partialPrice.reduce((a: any, b: any) => a + b)

    let totalPrice = totalPriceMainLoad + totalPartialPrice

    this.form.get('totalPrice')?.setValue(totalPrice)
    this.form.get('partialPrice')?.setValue(totalPartialPrice)
  }

  editPartial(index: number) {
    this.dialog.open(ViewEditLoadComponent, {
      data: {
        load: this.selectedLoad?.partial[index].loadId,
        drivers: this.drivers,
        carriers: this.carriers,
        dispatcers: this.dispathers,
        inputIdMap: this.inputIdMap + index,
      },
    });
  }

  addBrokerInformation() {
    let dialogRef = this.dialog.open(EditBrokerInfoComponent, {
      data: {
        brokers: this.brokers
      }
    });
    dialogRef.afterClosed().subscribe(responce => {
      if (responce) {
        this.populateForm(responce);
      }
    })

  }

  populateForm(data: any) {
    let broker = {
      businessName: data.company || '',
      fullName: data.name || '',
      id: data.brokerId || '0',
      email: data.email || '',
      phone: data.phone?.number || '',
      street: data.street || '',
      city: data.city || '',
      stateProvinceCode: '',
      countryCode: data.countryCode || '',
      zipCode: data.zipCode || '',
      notes: '',
      statusCode: '',
      statusName: '',
    }
    this.selectedLoad.broker = broker;

    this.form.patchValue({
      phone: data.phone?.number || '',
      company: data.company || '',
      brokerId: data.brokerId || '0',
      fullAddress: data.fullAddress || '',
      street: data.street || '',
      city: data.city || '',
      stateCode: data.stateCode || '',
      zipCode: data.zipCode || '',
      countryCode: data.countryCode || '',
      name: data.name || '',
      miles: data.miles || '',
      longitude: data.longitude || '',
      latitude: data.latitude || null,
      email: data.email || '',
    });
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  getDateFormArray(arr: any[]) {
    return arr.map((value) => new Date(value.dateScheduledFrom));
  }

  open(event: any) {
    console.log(event);
  }

  getTimeFormDate(date: any) {
    const timeString = date.split('T')[1].split('Z')[0].slice(0, 5);
    return timeString;

  }

  createFee(feeData?: any): FormGroup {
    return this.fb.group({
      id: [feeData?.id ? feeData?.id : 0],
      feeName: [feeData?.feeName ? feeData?.feeName : ''],
      feePrice: [feeData?.feePrice ? feeData?.feePrice : ''],
      addToGross: [feeData?.addToGross ? feeData?.addToGross : ''],
    });
  }

  getReference() {
    this.loadService.getReference().subscribe((value) => {
      value.forEach((el: any) => {
        if (el.type === 'FeeType') {
          this.feeType.push(el);
        } else if (el.type === 'LoadStatusLoad') {
          this.status.push(el)
        }
      });
    });
  }

  addPickup() {
    this.pickups.push(this.createPickup());
  }

  addDelivery() {
    this.delivery.push(this.createDelivery());
  }

  addFee() {
    this.openType = true;
    this.fee.push(this.createFee());
  }

  addPatrial() {
    this.partialLoad.push(this.createPartial());
  }

  removePickup(index: number) {
    this.pickups.removeAt(index);
    this.changeSelectedTab(index - 1, 'pickup');
  }
  removeDelivery(index: number) {
    this.delivery.removeAt(index);
    this.changeSelectedTab(index - 1, 'delivery');
  }
  removeFee(index: number) {
    this.fee.removeAt(index);
  }

  handleFileDelete(idx: number) {
    this.selectedLoad?.files.splice(idx, 1);
  }

  handleFileChange(files: Array<File>) {
    this.selectedLoad.files = files;
  }

  drop(event: CdkDragDrop<any[]>, type: string) {
    let controls: FormArray;
    let timeField: string;

    if (type === 'pickups') {
      controls = this.pickups;
      timeField = 'pickupTime';
    } else if (type === 'delivery') {
      controls = this.delivery;
      timeField = 'deliveryTime';
    } else {
      return;
    }

    const prevIndex = controls.controls.findIndex(
      (item) => item === event.item.data
    );
    const newIndex = event.currentIndex;

    const previousItem = controls.at(prevIndex).value;
    const currentItem = controls.at(newIndex).value;

    if (
      (previousItem[timeField] > currentItem[timeField] &&
        prevIndex < newIndex) ||
      (previousItem[timeField] < currentItem[timeField] && prevIndex > newIndex)
    ) {
      const copyPreviousItem = JSON.parse(JSON.stringify(previousItem));
      const copyCurrentItem = JSON.parse(JSON.stringify(currentItem));

      previousItem[timeField] = copyCurrentItem[timeField];
      previousItem.dateScheduledFrom = copyCurrentItem.dateScheduledFrom;
      previousItem.dateScheduledTo = copyCurrentItem.dateScheduledTo;
      previousItem.infoOrder = copyCurrentItem.infoOrder

      currentItem[timeField] = copyPreviousItem[timeField];
      currentItem.dateScheduledFrom = copyPreviousItem.dateScheduledFrom;
      currentItem.dateScheduledTo = copyPreviousItem.dateScheduledTo;
      currentItem.infoOrder = copyPreviousItem.infoOrder;

      controls.setControl(prevIndex, this.fb.group(currentItem));
      controls.setControl(newIndex, this.fb.group(previousItem));
    } else {
      const movedItem = controls.at(prevIndex);
      controls.removeAt(prevIndex);
      controls.insert(newIndex, movedItem);
    }

    controls.controls.forEach((control, index) => {
      control.patchValue({ infoOrder: index });
    });

    this.form.updateValueAndValidity();
  }

  onDrop(event: CdkDragDrop<number[]>) {
    moveItemInArray(this.stops, event.previousIndex, event.currentIndex);

    this.changeIndex(event.currentIndex)
    this.updateFormAndLoadAfterDrop()
    this.form.updateValueAndValidity();
  }

  async updateFormAndLoadAfterDrop() {
    this.stops.forEach((stop, index) => {
      stop.infoOrder = index;
    });

    this.pickups.controls.forEach((control) => {
      const stop = this.stops.find(value => value.id === control.get('id')?.value);
      control.patchValue({
        infoOrder: stop.infoOrder,
      });
    });

    this.delivery.controls.forEach((control) => {
      const stop = this.stops.find(value => value.id === control.get('id')?.value);
      control.patchValue({
        infoOrder: stop.infoOrder,
      });
    });
    if (this.selectedLoad?.partial) {
      this.selectedLoad?.partial.forEach((element: any) => {
        element.pickup.forEach((control: any) => {
          const stop = this.stops.find(value => value.id === control.id);
          control.infoOrder = stop.infoOrder;
        });

        element.delivery.forEach((control: any) => {
          const stop = this.stops.find(value => value.id === control.id);
          control.infoOrder = stop.infoOrder;
        });
      });
    }

    let load = this.formToLoad()
    let resonce = await this.loadService.checkLoadInfoOrder(load)
  }


  changeIndex(index: number) {
    this.currentPickupDelivery = this.stops[index];
    this.idx = index;
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  formatTime(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  createAddress() {
    this.pickups.controls.forEach((item: any, i: number) => {
      this.geometryService.findAddress('google-search' + this.inputPickUp + i, item);
    });

    this.delivery.controls.forEach((item: any, i: number) => {
      this.geometryService.findAddress('google-search' + this.inputDelivery + i, item);
    });

    this.geometryService.findAddress('google-search' + this.inputBroker, this.form);

    this.getLocations();
  }

  private async getLocations() {
    const pickups = this.form.get('pickups')?.value || [];
    const delivery = this.form.get('delivery')?.value || [];
    for (const el of [...pickups, ...delivery]) {
      if (!el.countryCode) {
        const regex = /^(.+),\s*(.+),\s*([A-Z]{2})\s*([\d-]+)?,\s*(.+)$/;
        const match = el.fullAddress?.match(regex);
        if (match) {
          const streetAddress = match[1];
          const city = match[2];
          const state = match[3];
          const zipCode = match[4];
          const country = match[5];

          el.street = streetAddress;
          el.city = city;
          el.zipCode = zipCode;
          el.stateCode = state;
          el.countryCode = country === 'США' ? 'USA' : country;
        }

        // try {
        //   const res: any = await firstValueFrom(this.geometryService.getPosition(el));
        //   el.latitude = res.bbox[1]
        //   el.longitude = res.bbox[0]
        // } catch (error) {
        //   console.log(error);
        // }
      }
    }
  }

  async initMap() {
    if (this.map) {
      this.map.remove();
    }
    let emptyMilesLocation: any = [];
    const pickupLocation = this.pickups.value[0];
    const deliveryLocation = this.delivery.value[0];
    if (this.selectedLoad.emptyMiles.type !== '' && this.selectedLoad.emptyMiles.type !== 'manually') {
      if ((this.selectedLoad.emptyMiles.latitude !== 0 && this.selectedLoad.emptyMiles.longitude !== 0)) {
        emptyMilesLocation = [{
          latitude: this.selectedLoad.emptyMiles.latitude,
          longitude: this.selectedLoad.emptyMiles.longitude
        }];
      } else {
        emptyMilesLocation = []
      }
    }
    console.log(emptyMilesLocation);

    const pickupLat = Number(pickupLocation.latitude);
    const deliveryLng = Number(deliveryLocation?.longitude);
    this.map = L.map(this.inputIdMap).setView([pickupLat, deliveryLng], 15);

    this.getHoursAndMinutes();

    this.layingOutThePath(
      [...this.pickups.value, ...this.partialPickUp],
      [...this.delivery?.value, ...this.partialDelivery],
      [...emptyMilesLocation]
    );
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy;',
    }).addTo(this.map);
  }

  private layingOutThePath(pickUp: any, delivery: any, emptyMiles: any) {
    const points: any[] = [];

    this.setMarkets(this.stops);
    if (emptyMiles) {
      this.setMarkets(emptyMiles);
    }

    this.sortArrByDateSheduledAndGetLatLong(pickUp, delivery, points);

    this.getDirectionByPoints(points);
    if (this.selectedLoad.emptyMiles.type !== '' && this.selectedLoad.emptyMiles.type !== 'manually') {
      this.directionBetweenFirstPickUpAndLastDelivery(pickUp[0], emptyMiles)
    }

    if (this.markers.length > 0) {
      let bounds = L.latLngBounds(this.markers);
      this.map.fitBounds(bounds);
    }

  }

  private setMarkets(arr: any) {
    let icon;
    for (let i = 0; i < arr.length; i++) {
      const lat = Number(arr[i].longitude);
      const lng = Number(arr[i].latitude);
      if (arr[i].infoType === "PICKUP") {
        icon = this.setIcon('assets/svg/location-pick-up-img.svg', this.getPickupNumber(i))
      } else if (arr[i].infoType === "DELIVERY") {
        icon = this.setIcon('assets/svg/checkpoint-img.svg', this.getDeliveryNumber(i))
      } else {
        icon = this.setIcon('assets/svg/current-location-pin-1.svg')
      }
      this.markers.push([lat, lng]);
      L.marker([lat, lng], { icon: icon }).addTo(this.map);
    }
  }

  private sortArrByDateSheduledAndGetLatLong(
    pickUp: any,
    delivery: any,
    points: any
  ) {
    [...pickUp, ...delivery]
      .sort((a, b) => a.infoOrder - b.infoOrder)
      .forEach((el) => {
        const lat = Number(el.latitude);
        const lng = Number(el.longitude);
        points.push([lat, lng]);
      });
  }

  private directionBetweenFirstPickUpAndLastDelivery(firstPickup: any, lastDelivery: any) {

    let points: any[] = [];
    [firstPickup, ...lastDelivery]
      .forEach((el) => {
        const lat = Number(el.latitude);
        const lng = Number(el.longitude);
        points.push([lat, lng]);
      });

    this.geometryService.getDirections(points).subscribe(
      (direction: any) => {
        const decodedGeometry = decode(direction.routes[0].geometry);
        const polyline = L.polyline(decodedGeometry, { color: 'grey ' }).addTo(this.map);
        this.map.fitBounds(polyline.getBounds());
      }
    )
  }

  private getDirectionByPoints(points: any) {
    this.geometryService.getDirections(points).subscribe(
      (direction: any) => {
        direction.routes[0].segments.forEach((value: any, index: number) => {
          if (this.stops[index]) {
            this.stops[index].distance = this.convertMetersToMiles(
              value.distance
            ).toFixed(2);
            this.stops[index].duration = this.convertSecondsToHoursMinutes(
              value.duration
            );
          }
        });
        const decodedGeometry = decode(direction.routes[0].geometry);
        const polyline = L.polyline(decodedGeometry).addTo(this.map);
        this.map.fitBounds(polyline.getBounds());
      },
      (e) => {
        console.log(e);
      }
    );
  }

  convertMetersToMiles(meters: number): number {
    return meters * 0.000621371; // 1 метр = 0.000621371 миль
  }

  convertSecondsToHoursMinutes(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }

  private setIcon(url: string, order?: number) {
    return L.divIcon({
      html: `
        <div style="
          position: relative;
          width: 30px;
          height: 30px;
          background-image: url('${url}');
          background-size: cover;
          text-align: center;
          font-weight: bold;
          color: black;
        ">
          <span style="
              position: absolute;
              top: 0px;
              left: 16px;
              width: 100%;
          ">${order ? order : ''}</span>
        </div>
      `,
      iconSize: [10, 30],
      iconAnchor: [15, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
    });
  }

  private clearMap() {
    if (!this.map) return;

    this.map.eachLayer((layer: any) => {
      if (layer instanceof L.Polyline || layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }

  private getHoursAndMinutes() {
    this.hours = Math.floor(this.selectedLoad?.driveTime / 3600);
    this.minutes = Math.floor((this.selectedLoad?.driveTime % 3600) / 60);
  }

  async updateLoad() {
    this.load = this.formToLoad();
    const areLoadMilesChanged: boolean = this.initialLoadedMiles !== this.form.get('loadMiles')?.value;
    this.aditionalCheckLoadBroker();
    try {
      let responce = await this.loadService.updateLoad(this.load.loadId, this.load, areLoadMilesChanged)
      if (responce) {
        this.dialogRef.close(responce)
      }
    } catch (err) {
      console.error(err);
    }
  }

  selectOptionInFee(event: any) {
    if (event === 'Plus to price') {
      this.fee.get('addToGross')?.setValue("PLUS_TO_PRICE");
    } else {
      this.fee.get('addToGross')?.setValue(false);
    }
    this.form.updateValueAndValidity();
  }

  aditionalCheckLoadBroker() {
    // @ts-ignore
    // return Object.keys(this.load.broker).some((key: keyof typeof Broker) => this.load.broker[key]);
    const broker = this.load.broker;

    if (
      (this.form.get('company')?.value === '' ||
        this.form.get('company')?.value === null) &&
      (this.form.get('brokerId')?.value === '' ||
        this.form.get('brokerId')?.value === null) &&
      (this.form.get('email')?.value === '' ||
        this.form.get('email')?.value === null) &&
      (this.form.get('name')?.value === '' ||
        this.form.get('name')?.value === null) &&
      (this.form.get('phone')?.value === '' ||
        this.form.get('phone')?.value === null) &&
      (this.form.get('fullAddress')?.value === '' ||
        this.form.get('fullAddress')?.value === null)
    ) {
      this.load.broker = null;
    }
  }

  formToLoad() {
    let deliveryLength = this.form.get('delivery')?.value;
    let driverId = this.getDriverNameById.getId(
      this.drivers,
      this.form.get('driver')?.value
    );
    let carrierId = this.getCarrierIdByName.getId(
      this.carriers,
      this.form.get('carrier')?.value
    );
    let dispatcherId = this.getDispatcherIdByName.get(
      this.dispathers,
      this.form.get('dispatcher')?.value
    );

    let pickupsArray = this.getPhoneNumberAndDateInFormArray.get(
      this.form.get('pickups') as FormArray
    );
    let deliveryArray = this.getPhoneNumberAndDateInFormArray.get(
      this.form.get('delivery') as FormArray
    );

    let load: Load = {
      customLoadId: this.form.get('customLoadId')?.value,
      parentId: this.form.get('parentId')?.value,
      broker: {
        businessName: this.form.get('company')?.value
          ? this.form.get('company')?.value
          : null,
        fullName: this.form.get('name')?.value,
        id: this.form.get('brokerId')?.value,
        email: this.form.get('email')?.value,
        phone: this.getPhoneNumberByObject(this.form.get('phone')?.value),
        street: this.form.get('street')?.value,
        city: this.form.get('city')?.value,
        stateProvinceCode: '',
        countryCode: this.form.get('countryCode')?.value,
        zipCode: this.form.get('zipCode')?.value,
        notes: this.form.get('notes')?.value,
        statusCode: '',
        statusName: '',
      },
      brokerFee: '',
      booking: this.form.get('booking')?.value,
      geometry: '',
      delivery: deliveryArray,
      driveTime: 0,
      driverId: driverId,
      driverName: this.form.get('driver')?.value,
      emptyMiles: {
        geometry: '',
        distance: this.form.get('miles')?.value,
        longitude: this.form.get('longitude')?.value,
        latitude: this.form.get('latitude')?.value,
        type: this.typeOfEmptyMiles,
      },
      files: this.selectedLoad?.files,
      history: this.selectedLoad?.loadsHistory,
      fees: this.form.get('fee')?.value,
      feeIncludesInPrice: null,
      insertDate: '',
      loadId: this.form.get('loadId')?.value,
      pickup: pickupsArray,
      price: this.form.get('price')?.value,
      partialPrice: this.form.get('partialPrice')?.value,
      totalPrice: this.form.get('totalPrice')?.value,
      status: this.form.get('status')?.value,
      loadMiles: this.form.get('loadMiles')?.value,
      carrierId: Number(carrierId),
      carrierName: this.form.get('carrier')?.value,
      dispatcher: {
        id: Number(dispatcherId),
        dispatcherFullName: this.form.get('dispatcher')?.value,
      },
      loadType: 'Load',
      partial: this.selectedLoad?.partial,
      dateTimeLineBgn: this.form.get('pickups')?.value[0].dateScheduledFrom,
      dateTimeLineEnd: this.form.get('delivery')?.value[deliveryLength?.length - 1]?.dateScheduledFrom,
    };

    return load;
  }

  async deleteLoadById() {
    this.selectedLoad.status = 'DELETED'
    try {
      this.loadService.changeStatusByLoadId(this.selectedLoad?.loadId, this.selectedLoad.status).subscribe(resp => {
        this.dialogService.notifyDialogClosed(JSON.parse(resp));
        this.dialogRef.close(resp)
      });
    } catch (err) {
      console.error(err);
    }
  }

  getPhoneNumberByObject(obj?: any) {
    if (obj && typeof obj !== 'string') {
      let phone = obj.number;
      return phone;
    }
    return '';
  }

  convertFormArrayToBoolean(formArray: FormArray): any[] {
    return formArray.controls.map((control) => {
      return {
        ...control.value,
        addToGross: this.convertToBoolean(control.get('addToGross')?.value),
      };
    });
  }

  convertToBoolean(value: string): boolean {
    return value === '+' ? true : false;
  }

  selectOption(selectedEmptyMilesOption: any) {
    let selectedOption = this.changedSelectedEmptyMilesOptionToCamelCase(
      selectedEmptyMilesOption
    );
    this.dropdownOpen = false;
    switch (selectedOption) {
      case 'lastDelivery':
        this.selectLastDelivery(selectedOption);
        break;
      case 'location':
        this.openDialog(selectedOption);
        break;
      case 'manually':
        this.setManualyEmptyMIles(selectedOption);
        break;
      case 'driverHome':
        this.calcEmptyMilesByDriversHome(selectedOption);
        break;
    }
  }

  setManualyEmptyMIles(type: string) {
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = true;
  }

  changedSelectedEmptyMilesOptionToCamelCase(selectedEmptyMilesOption: string) {
    return selectedEmptyMilesOption
      .split(' ')
      .reduce(
        (acc, word, index) =>
          acc +
          (index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
        ''
      );
  }

  openDropDownEmptyMiles() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  async openDialog(type: string) {
    this.typeOfEmptyMiles = type;
    this.openLocationModal = true;
    this.milesInputDisable = false;
    this.emptyMilesRequest.type = type;
  }

  async calcEmptyMilesByDriversHome(type: string) {
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = false;
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.type = type;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.driverId = this.form.get('driverId')?.value;
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    let emptyMilesDriversHome = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );
    this.form.get('miles')?.setValue(this.convertNumber(emptyMilesDriversHome?.distance));
    this.form.get('longitude')?.setValue(emptyMilesDriversHome.longitude);
    this.form.get('latitude')?.setValue(emptyMilesDriversHome.latitude);
    this.adjustWidthOnLoad()
  }

  async selectLastDelivery(type: string) {
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = false;
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.type = type;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.driverId = this.form.get('driverId')?.value;
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );
    this.form.get('miles')?.setValue(this.convertNumber(emptyMilesLastDelivery?.distance));
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude);
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude);
    this.adjustWidthOnLoad()
  }

  async getEmitCoordsFromMap(event: any) {
    let [latitude, longitude] = event.split(',');
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.targetLatitude = Number(latitude);
    this.emptyMilesRequest.targetLongitude = Number(longitude);
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    this.openLocationModal = false;
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );
    this.form.get('miles')?.setValue(this.convertNumber(emptyMilesLastDelivery?.distance));
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude);
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude);
    this.adjustWidthOnLoad()
  }

  setDateScheduledFrom(formArray: any) {
    const firstControl = formArray.at(0) as FormGroup;
    const dateScheduledFrom = firstControl.get('dateScheduledFrom')?.value;
    return this.parseStartDate(dateScheduledFrom);
  }

  adjustWidthOnLoad() {
    const inputElementMiles = this.milesInput?.nativeElement as HTMLInputElement;
    const inputElementLoad = this.loadInput?.nativeElement as HTMLInputElement;
    this.adjustWidth(inputElementMiles);
    this.adjustWidth(inputElementLoad);
  }

  adjustWidth(event: any) {
    const inputElement = event as HTMLInputElement;
    if (inputElement) {
      const length = inputElement?.value.length;
      if (length > 10) {
        return
      } else {
        inputElement.style.width = `${length + 1}ch`;
      }

    }
  }

  closeModalWithCoors(event: any) {
    this.openLocationModal = event.value;
  }

  isPartial(item: any): boolean {
    return (
      this.partialPickUp.includes(item) || this.partialDelivery.includes(item)
    );
  }

  openAutocomplete(evt: any) {
    evt.stopPropagation();
    this.selectFeeType.openPanel();
  }

  openTimePicker(id: string) {
    let time = document.getElementById(id) as HTMLInputElement;
    time?.showPicker();
  }

  convertNumber(num: number) {
    return Math.round(num)
  }

  private parseStartDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let localISOTime = `${year}-${month}-${day}T${hours}:${minutes}Z`;

    return localISOTime;
  }

}
