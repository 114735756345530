<div class="create-container">
  <form [formGroup]="form" class="create-load-from">
    <div class="header-inputs">
      <mat-form-field [ngStyle]="partial ? { width: '34%' } : { width: '25%' }" appearance="outline"
        class="custom-multi-input-cont">
        <mat-label>Dispatcher</mat-label>
        <mat-select #formItem #field1 formControlName="dispatcher" placeholder="Dispatcher"
          (keyup.enter)="focusNext('status')">
          @for (dispather of dispathers; track $index) {
          <mat-option (click)="inputFocus(field1)" [value]="dispather.fullName"
            id="allOption">{{dispather.fullName}}</mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field [ngStyle]="partial ? { width: '34%' } : { width: '25%' }" appearance="outline"
        class="custom-multi-input-cont">
        <mat-label>Status</mat-label>
        <mat-select #formItem #field2 formControlName="status" placeholder="Status" (keyup.enter)="focusNext('loadId')">
          @for (stat of status; track $index) {
          <mat-option (click)="inputFocus(field2)" [value]="stat.code" id="allOption">{{stat.name}}</mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <div *ngIf="!partial" class="block-inputs" #dropdownContainer>
        <label style="color: #667085; left: 18px;">Empty Miles</label>
        <input [readonly]="!milesInputDisable" style="width: 98%;" type="text" placeholder="Empty Miles"
          formControlName="miles" />
        <div style="margin: 10px 0;">
          <ul *ngIf="dropdownOpen" class="dropdown-list">
            <li *ngFor="let option of emptyMilesOptions; let i = index" [attr.data-value]="option"
              (click)="selectOption(option)" class="dropdown-item">
              {{ option }}
            </li>
          </ul>
        </div>
        <mat-icon (click)="openDropDownEmptyMiles()" style="cursor: pointer; z-index: 1;" matSuffix><img
            src="../../../../../../../assets/svg/setting-input-img.svg" alt=""></mat-icon>
      </div>

      <mat-form-field [ngStyle]="partial ? { width: '34%' } : { width: '25%' }" appearance="outline"
        class="custom-multi-input-cont">
        <mat-label>Load Miles</mat-label>
        <input #formItem matInput formControlName="loadMiles" type="text" style="z-index: 20;" readonly
          placeholder="Load Miles">
        <svg-icon src="assets/svg/search-icon.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

    </div>
    <div class="container">
      <div class="details">
        <strong>Details</strong>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Load ID</mat-label>
          <input #formItem matInput formControlName="loadId" type="text" required placeholder="Load ID"
            (keyup.enter)="focusNext('carrier')" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Carrier</mat-label>
          <mat-select #formItem #field3 (selectionChange)="getDriverByCarrierId($event.value)" matNativeControl
            formControlName="carrier" placeholder="Carrier" (keyup.enter)="focusNext('driver')">
            @for (carrier of carriers; track $index) {
            <mat-option (click)="inputFocus(field3)" [value]="carrier.id" id="allOption">{{carrier.name}}</mat-option>
            }
          </mat-select>
          <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Driver</mat-label>
          <mat-select #formItem #field4 (selectionChange)="setSelectedDriverIdInForm($event.value)" matNativeControl
            formControlName="driver" placeholder="Driver" (keyup.enter)="focusNext('price')">
            @for (filteredDriver of filteredDrivers; track $index) {
            <mat-option (click)="inputFocus(field4)" [value]="filteredDriver.id"
              id="allOption">{{filteredDriver.driverFullName}}</mat-option>
            }
          </mat-select>
          <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
        </mat-form-field>

      </div>
      <div class="payment">
        <strong>Payment</strong>
        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Price</mat-label>
          <input #formItem matInput formControlName="price" required type="number" placeholder="Price"
            (keyup.enter)="focusNext('feeName')" />
        </mat-form-field>

        <div style="display: flex; align-items: center; justify-content: space-between;">
          <strong>Add Fee</strong>
          <button class="pick-btn" (click)="addFee()" type="button"><img
              src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt="">
          </button>
        </div>
        <div formArrayName="fee" [style.display]="fee.controls.length === 0 ? 'none' : 'block'">
          <div *ngFor="let item of fee.controls; let i = index;" [formGroupName]="i">
            <div style="display: flex; justify-content: space-between; align-items: center; gap: 16px;" *ngIf="openType"
              [style.marginTop]="fee.controls.length === 0 ? 0 : '10px'">

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Fee Type</mat-label>
                <input #formItem #field5 matInput type="text" formControlName="feeName" [matAutocomplete]="type"
                  placeholder="Fee Type" (keyup.enter)="focusNext('addToGross')">
                <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
                  <mat-option (click)="inputFocus(field5)" *ngFor="let type of feeType" [value]="type.name"
                    id="allOption">
                    {{ type.name }}
                  </mat-option>
                </mat-autocomplete>
                <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Action</mat-label>
                <mat-select #formItem #field6 (selectionChange)="selectOptionInFee($event.value)"
                  formControlName="addToGross" placeholder="Select Action" (keyup.enter)="focusNext('feePrice')">
                  <mat-option (click)="inputFocus(field6)" *ngFor="let key of actionFeeKeys; let i = index"
                    id="allOption" [value]="key">
                    {{ actionsFee[key] }}
                  </mat-option>
                </mat-select>
                <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Price</mat-label>
                <input #formItem matInput placeholder="0" formControlName="feePrice" type="number" placeholder="Price"
                  (keyup.enter)="focusNext('businessName')" />
              </mat-form-field>

              <mat-icon style="height: 29px;" (click)="removeFee(i)" class="remove-icon"><img
                  src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div *ngIf="!openType || fee.controls.length === 0" class="block-input-icon">
            <mat-icon disabled><img src="../../../../../../../assets/svg/error-outline-img.svg"></mat-icon>
            <input disabled type="text" placeholder="No fee yet" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-information">
      <div class="pickup">
        <div style="display: flex; justify-content: space-between; align-items: baseline;">
          <strong>{{'Pickup Information '}}</strong>
          <button class="pick-btn" (click)="addPickup()" type="button">
            <img src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt="">
            {{'Add Pickup'}}</button>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; overflow: auto;">
              <div style="margin: 5px" *ngFor="let item of pickups.controls; let i = index" cdkDropList
                cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event,'pickups')">
                <button class="inactive-btn" cdkDrag (click)="changeSelectedTab(i, 'pickup')"
                  [ngClass]="{'active-btn': pIndex === i}" type="button">{{'Pickup '}}{{i + 1}}</button>
              </div>
            </div>
            <div style="display: flex;">
              <mat-icon class="remove-btn" *ngIf="pickups.controls.length > 1" (click)="removePickup(pIndex)"><img
                  src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
            </div>
          </div>
        </div>
        <div formArrayName="pickups">
          <div *ngFor="let pickup of pickups.controls; let i = index" [formGroupName]="i">
            <div *ngIf="pIndex === i">
              <div class="input-container">
                <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 6px;"
                  class="custom-multi-input-cont">
                  <mat-label>Shipper</mat-label>
                  <input #formItem matInput formControlName="businessName" type="text" placeholder="Shipper"
                    (keyup.enter)="focusNext('fullAddress')" />
                </mat-form-field>
              </div>
              <div class="input-container">
                <div style="width: 100%; justify-content: space-between;"
                  [style]="pickup?.get('fullAddress')?.invalid ? 'border-color: red' : '' " class="block-input-icon">
                  <mat-icon><img src="../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
                  <div class="fullAddress-container">
                    <input #formItem formControlName="fullAddress" [id]="'google-search' + 'p' + i" required
                      placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text"
                      (input)="onInputChange('pickup', i)" (blur)="onBlurMethodPickup()"
                      (keyup.enter)="focusNext('dateScheduledFrom')">
                  </div>
                  <div class="coordinates-wrappper">
                    <button class="coordinates-btn" (click)="addCoordinates('pickUp')" type="button">Add
                      coordinates</button>
                  </div>
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon"
                  [style]="pickup?.get('dateScheduledFrom')?.invalid ? 'border-color: red' : '' ">
                  <mat-datepicker-toggle matPrefix [for]="picker"><mat-icon class="date-picker-icon"
                      matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
                  <input #formItem formControlName="dateScheduledFrom" required class="date-picker-input"
                    [matDatepicker]="picker" placeholder="Pickup Date" required (keyup.enter)="focusNext('pickupTime')">
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="block-input-icon" [style]="pickup?.get('pickupTime')?.invalid ? 'border-color: red' : '' ">
                  <div class="centered"><img (click)="openTimePicker('pickupTime')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input #formItem class="custom-time-input" formControlName="pickupTime" id="pickupTime" type="time"
                      placeholder="Pickup Time" (keyup.enter)="focusNext('dateScheduledTo')">
                  </div>
                </div>
                <div class="block-input-icon">
                  <div class="centered">
                    <img (click)="openTimePicker('pickUpDateScheduledTo')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input #formItem class="custom-time-input" formControlName="dateScheduledTo" name="selectTime"
                      id="pickUpDateScheduledTo" type="time" placeholder="To" (keyup.enter)="focusNext('fullName')">
                  </div>
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Full Name</mat-label>
                  <input #formItem matInput formControlName="fullName" type="text" placeholder="Full Name"
                    (keyup.enter)="focusNext('notes')" />
                </mat-form-field>

                <ngx-intl-tel-input [preferredCountries]="[CountryISO.UnitedStates]" [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true" [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
                  [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true" [maxLength]="15"
                  [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Notes</mat-label>
                  <input #formItem matInput formControlName="notes" type="text" maxlength="85" placeholder="Add Notes"
                    (keyup.enter)="focusNext('businessName', 1)">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="delivery">
        <div style="display: flex; justify-content: space-between; align-items: baseline;">
          <strong>{{'Delivery Information '}}</strong>
          <button class="pick-btn" (click)="addDelivery(dIndex)" type="button"><img
              src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt="">{{'Add Delivery'}}</button>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; overflow: auto;">
            <div style="margin: 5px" *ngFor="let item of deliverys.controls; let i = index" cdkDropList
              cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event,'delivery')">
              <button class="inactive-btn" cdkDrag (click)="changeSelectedTab(i, 'delivery')"
                [ngClass]="{'active-btn-delivery': dIndex === i}" type="button">{{'Delivery '}}{{i + 1}}</button>
            </div>
          </div>
          <div style="display: flex;">
            <mat-icon class="remove-btn" *ngIf="deliverys.controls.length > 1" (click)="removeDelivery(dIndex)"><img
                src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
          </div>
        </div>
        <div formArrayName="deliverys">
          <div *ngFor="let delivery of deliverys.controls; let i = index" [formGroupName]="i">
            <div *ngIf="dIndex === i">
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont"
                  style="width: 100%;margin-bottom: 6px;">
                  <mat-label>Receiver</mat-label>
                  <input #formItem matInput formControlName="businessName" type="text" placeholder="Receiver"
                    (keyup.enter)="focusNext('fullAddress', 1)" />
                </mat-form-field>
              </div>

              <div class="input-container">
                <div style="width: 100%; justify-content: space-between;"
                  [style]="delivery?.get('fullAddress')?.invalid ? 'border-color: red' : '' " class="block-input-icon">
                  <mat-icon><img src="../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
                  <div class="fullAddress-container">
                    <input #formItem formControlName="fullAddress" [id]="'google-search' + 'd' + i" required
                      placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text"
                      (input)="onInputChange('delivery', i)" (keyup.enter)="focusNext('dateScheduledFrom',1)"
                      (blur)="onBlurMethodDelivery()">
                  </div>
                  <div class="coordinates-wrappper">
                    <button class="coordinates-btn" (click)="addCoordinates('delivery')" type="button"> Add
                      coordinates</button>
                  </div>
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon"
                  [style]="delivery?.get('dateScheduledFrom')?.invalid ? 'border-color: red' : '' ">
                  <mat-datepicker-toggle matPrefix [for]="picker"><mat-icon class="date-picker-icon"
                      matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
                  <input #formItem class="date-picker-input" required formControlName="dateScheduledFrom"
                    [matDatepicker]="picker" placeholder="Delivery Date" (keyup.enter)="focusNext('deliveryTime')">
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="block-input-icon"
                  [style]="delivery?.get('deliveryTime')?.invalid ? 'border-color: red' : '' ">
                  <div class="centered">
                    <img (click)="openTimePicker('deliveryTime')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input #formItem class="custom-time-input" formControlName="deliveryTime" id="deliveryTime"
                      type="time" placeholder="Delivery Time" (keyup.enter)="focusNext('dateScheduledTo', 1)">
                  </div>
                </div>
                <div class="block-input-icon">
                  <div class="centered">
                    <img (click)="openTimePicker('deliverydateScheduledTo')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="">
                    <input #formItem (keyup.enter)="focusNext('fullName', 1)" class="custom-time-input"
                      formControlName="dateScheduledTo" id="deliverydateScheduledTo" type="time" placeholder="To">
                  </div>
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Full Name</mat-label>
                  <input #formItem (keyup.enter)="focusNext('notes', 1)" matInput formControlName="fullName" type="text"
                    placeholder="Full Name" />
                </mat-form-field>
                <div class="block-inputs">
                  <ngx-intl-tel-input style="width: 100%;" [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true"
                    [maxLength]="15" [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Notes</mat-label>
                  <input #formItem (keyup.enter)="focusNext('company')" matInput formControlName="notes" type="text"
                    maxlength="85" placeholder="Add Notes">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div class="customer-broker-info">
        <mat-icon style="height: 29px;" (click)="clearBroker()" class="remove-btn remove-broker-icon"><img
            src="assets/svg/remove-img.svg"></mat-icon>
        <strong>Customer/Broker Information</strong>
        <div style="margin: 0;" class="input-container">
          <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
            <mat-label>Company</mat-label>
            <input #formItem (keyup.enter)="focusNext('fullAddress', 2)" matInput type="text" formControlName="company"
              [matAutocomplete]="auto" #inputAutoComplete (input)="brokerInput($event)" placeholder="Select Company"
              matAutocompletePosition="below">
            <mat-autocomplete #auto="matAutocomplete"
              (optionSelected)="addInformationAboutBrokerByName($event.option.value)">
              @for (broker of filteredBroker| async; track brokers) {
              <mat-option (click)="auto.focus()" [value]="broker" id="allOption">{{broker.businessName}}</mat-option>
              }
            </mat-autocomplete>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont addres-input  full-width-input">
            <mat-label>Full Address</mat-label>
            <input #formItem (keyup.enter)="focusNext('name')" style="width: calc(100% - 20px);" matInput
              [id]="'google-search' + 'b'" type="text" formControlName="fullAddress" type="text"
              placeholder="Full Address">
            <svg-icon class="icon-prefix" src="assets/svg/location-on-img.svg"></svg-icon>
          </mat-form-field>
        </div>
        <div style="margin: 0;" class="input-container">
          <mat-form-field style="width: 49%;" appearance="outline" class="custom-multi-input-cont">
            <mat-label>Name</mat-label>
            <input #formItem (keyup.enter)="focusNext('email')" matInput formControlName="name" type="text"
              placeholder="Name">
          </mat-form-field>

          <div style="width: 49%;" class="block-inputs">
            <ngx-intl-tel-input [preferredCountries]="[CountryISO.UnitedStates]" [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true" [maxLength]="15"
              [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
          </div>
        </div>
        <div style="margin: 0;" class="input-container">
          <mat-form-field appearance="outline" class="custom-multi-input-cont email-input full-width-input">
            <mat-label>Email</mat-label>
            <input #formItem (keyup.enter)="focusNext('carrier')" matInput formControlName="email" type="text"
              placeholder="Email">
            <svg-icon class="icon-prefix" src="assets/svg/email-img.svg"></svg-icon>
          </mat-form-field>
        </div>
      </div>
      <app-file-upload [files]="files" (filesChanged)="handleFileChange($event)"></app-file-upload>
    </div>
  </form>
  <div *ngIf="openMap" class="map-frame">
    <div class="search-container">
      <strong>Search coordinates</strong>
      <div style="display: flex; justify-content: space-around; gap: 12px;">
        <div class="block-input-icon">
          <input type="text" placeholder="Enter coordinates" [(ngModel)]="longitudeAndLatitude"
            (input)="manualInputOfCoordinates()">
          <mat-icon matSuffix><img src="../../../../../../../../assets/svg/search-img.svg"></mat-icon>
        </div>
        <div>
          <button class="save-crd-btn" (click)="addCoordinatesInForm()" type="button">Save</button>
        </div>
      </div>
    </div>
    <div (click)="setMarkerForCoordinates()" class="full" id="map"></div>
  </div>
  <div *ngIf="openLocationModal" style="width: 100%; height: 100%;
      display: flex;justify-content: center;align-items: center; position: absolute; z-index: 23; top: 0;
      background: rgb(128, 128, 128, 0.5);">
    <app-location-empty-miles (addNewItem)="getEmitCoordsFromMap($event)"
      (closeModalWithCoors)="closeModalWithCoors($event)"></app-location-empty-miles>
  </div>
</div>