@defer () {
<mbsc-eventcalendar #eventcalendar themeVariant="light" [scheduleEventTemplate]="eventTemp" [view]="view"
  [colors]="weekendCellsColor" [dragToCreate]="true" (onEventDragStart)="dragStart($event)"
  (onEventDragEnd)="dragEnd($event)" [invalid]="invalid" [data]="myEvents" [resources]="myResources"
  [resourceTemplate]="resourceTemp"
  [sidebarHeaderTemplate]="!settingsFormHeader?.hideTotalBy ? customTemplateHeader : ''"
  [sidebarTemplate]="!settingsFormHeader?.hideTotalBy ? customTemplate : ''" [(selectedDate)]="selectedDate"
  mbsc-start-icon="prev" [options]="calendarOptions" [resourceHeaderTemplate]="headerTemp"
  [dayTemplate]="dayTemplateHTML">
  <ng-template #dayTemplateHTML let-date>
    <div class="custom-day" [ngClass]="date.isActive ? 'active-custom-day' : 'custom-day'">
      <div>
        <span class="day-event">{{getDateMonth(date.date)}}</span>
        <span class="day-number">{{getDateDay(date.date)}}</span>
      </div>
      <div style="display: flex; justify-content: center;" *ngIf="date.isActive">
        <span class="time-event">{{browserTime}}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #eventTemp let-event>
    <div
      *ngIf="event.original.status !== 'TONU' && event.original.status !== 'DELETED' && event.original.type !== 'Note'"
      class="event" [style]="{
        width: 'auto',
        margin: '3px 1px 0 1px',
        height: !event.original.status ? '47px' : '',
        'border-left': event.original.status
          ? checkStatus(event)
          : 'none',
        'background-color': !event.original.status ? '#d9d9d9' : 'transparent'
      }" (click)="editLoad(event)">
      <div *ngIf="
          event.original.loadType === 'Load' && settingsFormHeader?.showLoadId
        " class="head-costom-load">
        <span class="head-constom-load-load-title">
          {{ "#" + event.original.title }}
        </span>
        <span *ngIf="event.original.moreThanOneDay && event.original.pickup && event.original.delivery &&
        event.original.pickup.length &&
        event.original.delivery.length &&
        (
          !event.original.pickup[0].city ||
          !event.original.pickup[0].street ||
          !event.original.pickup[0].zipCode ||
          !event.original.delivery[0].city ||
          !event.original.delivery[0].street ||
          !event.original.delivery[0].zipCode
        )" class="head-constom-load-load-icons"><img src="../../../../../../assets/svg/location-img.svg" /></span>
        <span *ngIf="
            event.original.moreThanOneDay && event.original.partial?.length > 0
          " class="head-constom-load-load-icons"><img src="../../../../../../assets/svg/car-img.svg" /></span>
        <span *ngIf=" event.original.fees?.length > 0
          " class="head-constom-load-load-icons"><img src="../../../../../../assets/svg/fees-img.svg" /></span>
        <span *ngIf=" (event.original.pickup[0].notes || event.original.delivery[0].notes)
        " class="head-constom-load-load-icons"><span class="head-constom-load-load-note">{{'NOTE'}}</span></span>
        <span *ngIf="(event.original.splitLoad)
      " class="head-constom-load-load-icons"><span class="head-constom-load-load-note">{{'SPLIT'}}</span></span>
      </div>
      <div *ngIf="
          event.original.moreThanOneDay && event.original.loadType === 'Load'
        " class="custom-load">
        <div style="
            display: flex;
            justify-content: space-between;
            height: 49%;
            overflow: hidden;
          ">
          <span *ngIf="event.original.pickup">
            {{
            ( event.original?.firstPickup?.city ? event.original?.firstPickup?.city + ',' : '') +'
            '+event.original?.firstPickup?.stateCode
            }}</span>
          <span *ngIf="event.original.pickup && event.original.delivery">-></span>
          <span *ngIf="event.original.delivery">
            {{(event.original?.lastDelivery?.city ? event.original?.lastDelivery?.city+',' : '') +'
            '+event.original?.lastDelivery?.stateCode}}
          </span>
        </div>
        <div style="display: flex; height: 49%; overflow: hidden">
          <span>{{ "$" }}</span>
          <span *ngIf="settingsFormHeader?.showPricePerLoad" style="margin-right: 10px; display: inline-block">{{
            event.original.totalPrice }}</span>
          <span *ngIf="settingsFormHeader?.showMiles" style="margin-right: 10px">{{
            convertNumber(event.original.totalMi) }}
            <span>{{ "mi" }}</span></span>
          <span *ngIf="settingsFormHeader?.showRatePerMiles">{{ event.original.rateMile }} <span>{{ "$/mi"
              }}</span></span>
        </div>
      </div>
      <div *ngIf="
          !event.original.moreThanOneDay && event.original.loadType === 'Load'
        " class="custom-load">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            overflow: hidden;
          ">
          <span>{{ event.original.pickup[0]?.stateCode }}</span> <span>-></span>
          <span>{{ event.original.delivery[0]?.stateCode }}</span>
        </div>
        <div style="display: flex; height: 49%; overflow: hidden">
          <span *ngIf="settingsFormHeader?.showPricePerLoad" style="margin-right: 10px; display: inline-block"><span>{{
              "$" }}</span>{{
            event.original.totalPrice }}</span>
          <span *ngIf="settingsFormHeader?.showMiles" style="margin-right: 10px">{{
            convertNumber(event.original.totalMi) }}
            <span>{{ "mi" }}</span></span>
          <span *ngIf="settingsFormHeader?.showRatePerMiles">{{ event.original.rateMile }} <span>{{ "$/mi"
              }}</span></span>
        </div>
      </div>
    </div>
    <div *ngIf="event.original.status === 'TONU'" class="event-tonu mbsc-schedule-event mbsc-ltr" [style]="{
      width: '7px',
      margin: '3px 1px 0 1px',
      'height': '70px',
      'border': '4px solid rgb(217, 45, 32)',
    }" (click)="editLoad(event)"></div>

    <div (click)="editNote(event)" *ngIf="event.original.type === 'Note'" class="event" [style]="{
      width: 'auto',
      margin: '3px 1px 0 1px',
      'border-left': '7px solid #667085',
      'background-color': 'transparent'
    }">
      <div *ngIf="event.original.type === 'Note'" class="head-costom-load">
        <span class="head-constom-load-load-title">
          {{ "Reason" + ":" + " " + event.original?.reason }}
        </span>
      </div>
      <div *ngIf="event.original.type === 'Note'" class="custom-load">
        <div *ngIf="event.original?.reason === 'Other'">
          <div class="other-reason">
            <p>{{event.original.otherReason}} </p>
          </div>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #headerTemp>
    <div class="drivers-header" (click)="openFilterMenu()">
      <mat-icon style="color: #667085"><img src="../../../../../../assets/svg/filter-img.svg" /></mat-icon>
      {{ "Driver List" }}
    </div>
  </ng-template>

  <ng-template #resourceTemp let-resource>
    <div style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      " (click)="openNotePopup(resource)">
      <div class="initials" *ngIf="resource.driverFullName">
        {{ getInitials(resource.driverFullName) }}
      </div>
      <div class="names">
        <div class="md-resource-header-template-name driver-name text-style-name"
          [attr.data-driver-id]="resource.driverId">
          <span class="overflow-ellipsis">
            {{ resource.driverFullName }}
          </span>
          <span *ngIf="resource?.coDriverId && coDriverId?.coDriverId !== 0" class="badge"
            [appTooltip]="myTooltipContent" [appTooltipPos]="coDriverTooltipPos">
            {{ "+1" }}
          </span>
          <svg-icon class="edit-icon-under" src="/assets/svg/pencil-edit-underline.svg"
            (click)="editDriver(resource, $event)"></svg-icon>
          <ng-template #myTooltipContent>
            <div class="codriver-tooltip">
              <span>Co-Driver</span>
              <span class="codriver-name">
                {{ resource.coDriverFullName }}</span>
            </div>
          </ng-template>
        </div>
        @if(settingsFormHeader?.showTruck) {
        <div class="md-resource-header-template-name text-style">
          <span class="overflow-ellipsis">{{ resource.vehicleUnit }}</span>
          <svg-icon class="edit-icon-under" src="/assets/svg/pencil-edit-underline.svg"
            (click)="editVehicleClick(resource, $event)"></svg-icon>
        </div>
        } @else {
        <div class="md-resource-header-template-name text-style">
          <span class="overflow-ellipsis">{{ resource.carrierName }}</span>
          <svg-icon class="edit-icon-under" src="/assets/svg/pencil-edit-underline.svg"
            (click)="editCarrier(resource, $event)"></svg-icon>
        </div>
        } @if(settingsFormHeader?.showTrailer) {
        <div class="md-resource-header-template-name text-style">
          <span class="overflow-ellipsis">{{ resource.trailerUnit }}</span>
          <svg-icon class="edit-icon-under" src="/assets/svg/pencil-edit-underline.svg"
            (click)="editTrailer(resource, $event)"></svg-icon>
        </div>
        } @else {
        <div class="md-resource-header-template-name text-style">
          <span class="overflow-ellipsis">{{
            resource.dispatcherFullName
            }}</span>
          <svg-icon class="edit-icon-under" src="/assets/svg/pencil-edit-underline.svg"
            (click)="editDispatcher(resource, $event)"></svg-icon>
        </div>
        }
      </div>
    </div>
  </ng-template>
  <div>
    <ng-template #customTemplateHeader>
      <div class="create-drop-down">
        <button class="total-by-btn" (click)="openDropTotal()">
          {{ "Total By" }}
          <mat-icon style="cursor: pointer; color: #667085" matSuffix>keyboard_arrow_down</mat-icon>
        </button>
        <div>
          <div *ngIf="openDropDownTotal" style="z-index: 10;" class="dropdown-list">
            <div [attr.data-value]="option" (click)="calcPrice('PICKUP')" class="dropdown-item">
              {{ "Pick Up" }}
            </div>
            <div [attr.data-value]="option" (click)="calcPrice('DELIVERY')" class="dropdown-item">
              {{ "Delivery" }}
            </div>
          </div>
        </div>
      </div>

    </ng-template>
    <ng-template #customTemplate let-resource>
      <div class="total-hover" (click)="addCalcLoad(resource)">
        <div class="side-bar-font">
          <span class="f-w-600">{{ "$"}}{{convertNumber(resource?.totalPrice) }}</span>
        </div>
        <div class="side-bar-font">
          <span class="f-w-600">{{ convertNumber(resource?.loadMiles) }}</span>{{ " mi" }}
        </div>
        <div class="side-bar-font">
          <span class="f-w-600">{{ resource?.rate?.toFixed(2) }}</span>{{ " $/mi" }}
        </div>
      </div>
    </ng-template>
  </div>
</mbsc-eventcalendar>
<div class="timeline-footer-line">
  <div style="padding-right: 10px">
    <span class="total-info">${{ totalPrice }}</span>
    {{ " | " }}
    <span class="total-info">{{ totalMi }}</span>
    mi
    {{ " | " }}
    <span class="total-info">{{ totalRatePerMi?.toFixed(2) }}</span>
    $/mi
  </div>
</div>

<mbsc-popup class="md-tooltip" #popup [anchor]="anchor" [options]="popupOptions">
  <div style="padding: 24px" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" *ngIf="toolTipImformation">
    <div class="md-tooltip-header">
      <div style="display: flex; gap: 16px;">
        <div class="container-icon">
          <mat-icon><img src="../../../../../../assets/svg/information-circle-stroke-rounded.svg" /></mat-icon>
        </div>
        <div>
          <div class="bolt">{{ toolTipImformation.driverName }}</div>
          <div class="carrier-name">{{ toolTipImformation.carrierName }}</div>
        </div>
      </div>
      <div>
        <div class="bolt">{{ "#" + toolTipImformation.customLoadId }}</div>
      </div>
    </div>
    <div style="height: 20px;"></div>
    <div class="md-tooltip-info">
      <div class="md-tooltip-title">
        <div class="main-container-status-dropdown-menu">
          <div>{{'Status:'}}</div>
          <div (click)="isDropdownOpen = ! isDropdownOpen" class="status-dropdown-menu">
            <div [style]="{'color':checkStatusForBackground(toolTipImformation.status)}" style="margin: 0 !important;">
              <div [style]="{'background-color':checkStatusForBackground(toolTipImformation.status)}" class="dot"></div>
              {{toolTipImformation.status}}
            </div>
            <img style="width: 12px; height: 12px;" src="../../../../../../assets/svg/chevron-down.svg">
          </div>
          <div *ngIf="isDropdownOpen" class="custom-status-dropdown">
            <div (click)="selectStatus(toolTipImformation.loadId, status)" class="custom-status-dropdown-option"
              *ngFor="let status of statusOptions">
              {{this.convertToTitleCase(status)}}</div>
          </div>
        </div>
        <div>
          Rate: <span class="info">{{ toolTipImformation.price }}</span>
        </div>
        <div style="display: flex; gap: 5px;">
          Fee:
          <span class="info">{{toolTipImformation.fee }}</span>
          <span class="badge" *ngIf="toolTipImformation.feesLength > 1">
            {{ "+" + (toolTipImformation.feesLength - 1) }}
          </span>
        </div>
        <div>
          Pickup: <span class="info">{{ toolTipImformation.pickUp }}</span>
        </div>
        <div>
          Delivery: <span class="info">{{ toolTipImformation.delivery }}</span>
        </div>
        <div>
          D/H: <span class="info">{{ convertNumber(toolTipImformation.dh) }}</span>
        </div>
        <div>
          Miles: <span class="info">{{ convertNumber(toolTipImformation.miles) }}</span>
        </div>
        <div>
          Total miles:
          <span class="info">{{ convertNumber(toolTipImformation.totalMiles) }}</span>
        </div>
        <div>
          Rate per mile: <span class="info">{{ toolTipImformation.rate }}</span>
        </div>
        <div>
          Duration: <span class="info">{{ toolTipImformation.duration }}</span>
        </div>
      </div>
    </div>
  </div>
</mbsc-popup>

<div *ngIf="isMenuOpen" class="driver-filter">
  <div [ngClass]="isMenuOpen ? 'header-main-container' : 'close-menu'" (click)="$event.stopPropagation()">
    <div class="filter-title">Filter</div>
    <div class="close-icon-container">
      <svg-icon class="filter-icon" src="assets/svg/close-icon.svg" (click)="closeMenu()"></svg-icon>
    </div>
  </div>
  <div class="flter-body">
    <mat-accordion multi>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>Driver</mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <mat-radio-group [(ngModel)]="selectedSortingByDriver" class="global-radio-btn">
            <div class="section">
              <mat-icon>
                <img src="../../../../../../assets/svg/a-z-img.svg" />
              </mat-icon>
              <label class="dl-label-style">Alphabetical Asc. (A -> Z)</label>
              <mat-radio-button [value]="0"></mat-radio-button>
            </div>
            <div class="section">
              <mat-icon>
                <img src="../../../../../../assets/svg/z-a-img.svg" />
              </mat-icon>
              <label class="dl-label-style">Alphabetical Dsc. (Z -> A)</label>
              <mat-radio-button [value]="1"></mat-radio-button>
            </div>
          </mat-radio-group>
        </section>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>Dispatcher</mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <mat-radio-group [(ngModel)]="selectedSortingByDispather" class="global-radio-btn">
            <div class="section">
              <mat-icon>
                <img src="../../../../../../assets/svg/a-z-img.svg" />
              </mat-icon>
              <label class="dl-label-style">Alphabetical Asc. (A -> Z)</label>
              <mat-radio-button [value]="0" color="primary"></mat-radio-button>
            </div>
            <div class="section">
              <mat-icon>
                <img src="../../../../../../assets/svg/z-a-img.svg" />
              </mat-icon>
              <label class="dl-label-style">Alphabetical Dsc. (Z -> A)</label>
              <mat-radio-button [value]="1" color="primary"></mat-radio-button>
            </div>
          </mat-radio-group>
        </section>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>Company</mat-panel-title>
        </mat-expansion-panel-header>
        <section>
          <div *ngFor="let company of allCarriers; let i = index" class="filter-checkbox">
            <mat-icon>
              <img src="assets/svg/a-z-img.svg" />
            </mat-icon>
            <label class="dl-label-style">{{ company.name }}</label>
            <input [id]="'cb' + [i]" class="custom-checkbox" type="checkbox" (change)="selectedCarriers($event)"
              [checked]="company.selectedCarrier" value="{{company.name}}" /><label [for]="'cb' + [i]"></label>
          </div>
        </section>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="this.role === 'Supervisor'" (opened)="panelOpenState.set(true)"
        (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title>Supervisor</mat-panel-title>
        </mat-expansion-panel-header>
        <section class="filter-checkbox">
          <mat-icon>
            <img src="assets/svg/a-z-img.svg" />
          </mat-icon>
          <label class="dl-label-style">Show only my drivers</label>
          <input [id]="'cbs' + [i]" class="custom-checkbox" type="checkbox" (change)="onCheckboxChange($event)"
            [checked]="showAllDriversOfSupervisor" /><label [for]="'cbs' + [i]"></label>
        </section>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="filter-btns">
      <button mat-stroked-button (click)="resetFilter()">Clear</button>
      <button mat-flat-button (click)="applyFilters()"
        style="background-color: #175cd3; color: #ffffff; padding: 6px 12px">
        Apply
      </button>
    </div>
  </div>
</div>
}