<div class="edit-driver-form-wrapper">
    <form [formGroup]="driverForm">
        <div class="form-caption">Account</div>
        <div class="row-2" >
            <mat-form-field  class="global-select-input">
                <mat-label *ngIf="!driverForm.get('carrierId')?.value && driverForm.get('carrierId')?.value !== 0">Carrier</mat-label>
                
                <mat-select *ngIf="!customCarrierInputVisible" formControlName="carrierId" panelClass="dropDwonMultipleFixClass">
                  <mat-option *ngFor="let carrier of carriers; let i = index; trackBy: trackByFn" [value]="carrier.id" id="allOption" >
                    {{ carrier.name }}
                  </mat-option>
                </mat-select>

                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('carrierId')?.invalid && driverForm.get('carrierId')?.touched">
                  <mat-error *ngIf="driverForm.get('carrierId').errors?.required">
                    <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                    Carrier is required
                  </mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="global-select-input">
                @if (!driverForm.get('dispatcherId')?.value) {
                    <mat-label>Dispatcher</mat-label>
                }
                <mat-select formControlName="dispatcherId" panelClass="dropDwonMultipleFixClass">
                @for (dispatcher of dispatchers; track $index) {
                    <mat-option id="allOption" [value]="dispatcher.id">{{dispatcher.fullName}}</mat-option>
                }
                </mat-select>
                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('dispatcherId')?.invalid && driverForm.get('dispatcherId')?.touched">
                    <mat-error *ngIf="driverForm.get('dispatcherId').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Dispatcher is required
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row-2">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name*">
                <div class="input-err" *ngIf="driverForm.get('firstName')?.invalid && driverForm.get('firstName')?.touched">
                    <div *ngIf="driverForm.get('firstName').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        FirstName is required
                    </div>
                </div>
            </div>
    
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name">
                <div class="input-err" *ngIf="driverForm.get('lastName')?.invalid && driverForm.get('lastName')?.touched">
                    <div *ngIf="driverForm.get('lastName').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        LastName is required
                    </div>
                </div>
            </div>
        </div>

        <div class="row-2">
            <div class="block-input-icon-with-err">
                <svg-icon src="assets/svg/mail.svg"></svg-icon>
                <input formControlName="email" type="email" mode="Search" placeholder="Email*" />
                @if(isEmailLoading) {
                    <mat-spinner class="email-spinner" mode="indeterminate" diameter="20"></mat-spinner>
                }
                <div class="input-err" *ngIf="driverForm.get('email')?.invalid && driverForm.get('email')?.touched">
                    <div *ngIf="driverForm.get('email').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Email is required
                    </div>
                    <div *ngIf="driverForm.get('email').errors?.email">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Invalid email format
                    </div>
                    <div *ngIf="driverForm.get('email').errors?.emailTaken">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                        Unavailable
                    </div>
                </div>
            </div>   
            <div class="block-input-icon-with-err">
                <ngx-intl-tel-input style="width: 100%;" 
                    [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" 
                    [enablePlaceholder]="true" 
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true" 
                    [selectedCountryISO]="CountryISO.UnitedStates" 
                    [separateDialCode]="true"
                    (countryChange)="onCountryChange($event)"
                    [maxLength]="15" 
                    [phoneValidation]="true"
                    name="phone" 
                    formControlName="phone">
                </ngx-intl-tel-input>
                
                <div class="input-err" *ngIf="driverForm.get('phone')?.invalid && driverForm.get('phone')?.touched">
                    <div *ngIf="driverForm.get('phone').errors?.required">
                    <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                    Phone number is required
                    </div>
                    <div *ngIf="driverForm.get('phone').errors && driverForm.get('phone').value">
                    <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                    Invalid phone number
                    </div>
                </div>
            </div>
        </div>

        <div class="row-2" >
            <mat-form-field class="global-select-input cust-autocomplete">
                @if (!driverForm.get('vehicleId')?.value && driverForm.get('vehicleId')?.value !== 0) {
                    <mat-label>Vehicle</mat-label>
                }

                <input type="text" matInput formControlName="vehicleId" [matAutocomplete]="autoVehicle" />
                <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="vehicleDisplayFn.bind(this)" >
                    <mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle.id" id="allOption">
                    {{ vehicle.vehicleUnit }}
                    </mat-option>
                </mat-autocomplete>

                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('vehicleId')?.invalid && driverForm.get('vehicleId')?.touched">
                    <mat-error *ngIf="driverForm.get('vehicleId').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Vehicle is required
                    </mat-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="global-select-input cust-autocomplete">
                @if (!driverForm.get('trailerId')?.value && driverForm.get('trailerId')?.value !== 0) {
                    <mat-label>Trailer</mat-label>
                }
                <input type="text" matInput formControlName="trailerId" [matAutocomplete]="autoTrailer" />
                <mat-autocomplete #autoTrailer="matAutocomplete" [displayWith]="trailerDisplayFn.bind(this)">

                <mat-option *ngFor="let trailer of filteredTrailers | async" [value]="trailer.id" id="allOption">
                    {{ trailer.trailerUnit }}
                </mat-option>
                </mat-autocomplete>

                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('trailerId')?.invalid && driverForm.get('trailerId')?.touched">
                    <mat-error *ngIf="driverForm.get('trailerId').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        Trailer is required
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        @if(row.coDriverVisible) {            
            <div class="form-caption">Co-Driver <span *ngIf="!row.tempCreateDriver && row.coDriverId" [ngClass]="{ 'disabled-btn': !driverForm.valid }" (click)="editCodriverClick(row)"><svg-icon src="assets/svg/pencil-edit-underline.svg"></svg-icon>Edit</span></div>
            @if(!row.coDriverId) {
                <div class="codriver-container" appDebounceClick (debounceClick)="addCoDriverClick(row)" [ngClass]="{ 'disabled-btn': !driverForm.valid }">
                    <div class="co-driver-btn">
                        + Add Co-Driver
                    </div>
                </div>
            } @else {
                <div class="row-2" >
                    <div class="block-input-icon-with-err">
                        <input type="text" class="form-control" id="firstName" [value]="row.coDriverFirstName" placeholder="First Name" readonly>
                    </div>
                    <div class="block-input-icon-with-err">
                        <input type="text" class="form-control" id="lastName" [value]="row.coDriverLastName" placeholder="Last Name" readonly>
                    </div>
                </div>
            }
        }

        <div class="form-caption">License</div>

        <div class="row-1">            
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="driverLicenseNumber" formControlName="driverLicenseNumber" placeholder="License Number">
            </div>
        </div>

        <div class="row-2">
            <mat-select-country
                class="select-country"
                appearance="fill"
                formControlName="driverLicenseCountryCode"
                [itemsLoadSize]="50"
                autocomplete="true">
            </mat-select-country>
            <mat-form-field class="global-select-input">
                @if (!driverForm.get('driverLicenseStateCode')?.value) {
                    <mat-label>State/Province</mat-label>
                }
                <mat-select formControlName="driverLicenseStateCode" panelClass="dropDwonMultipleFixClass">
                    @for (state of statesL; track $index) {
                        <mat-option id="allOption" [value]="state.name">{{state.name}}</mat-option>
                    }
                </mat-select>
                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('driverLicenseStateCode')?.invalid">
                    <mat-error *ngIf="driverForm.get('driverLicenseStateCode').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        State code is required
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-caption">Home</div>
        
        <div class="row-2">
            <div class="block-input-icon-with-err fix-google-locations">
                <input formControlName="fullAddress" id="{{'google-search' + row.gSearchID}}" placeholder="Full Address" type="text" (focus)="fullAdressFocus()">
            </div>
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="street" formControlName="street" placeholder="Street">
            </div>
        </div>

        <div class="row-2">
            <mat-select-country
                class="select-country"
                appearance="fill"
                formControlName="countryCode"
                [itemsLoadSize]="50"
                autocomplete="true">
            </mat-select-country>

            <mat-form-field class="global-select-input">
                @if (!driverForm.get('stateCode')?.value) {
                    <mat-label>State/Province</mat-label>
                }
                <mat-select formControlName="stateCode" panelClass="dropDwonMultipleFixClass">
                @for (state of statesH; track $index) {
                    <mat-option id="allOption" [value]="state.isoCode">{{state.name}}</mat-option>
                }
                </mat-select>
                <mat-error class="input-err custom-err-select" *ngIf="driverForm.get('stateCode')?.invalid">
                    <mat-error *ngIf="driverForm.get('stateCode').errors?.required">
                        <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                        State code is required
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row-2">
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="city" formControlName="city" placeholder="City">
            </div>
            <div class="block-input-icon-with-err">
                <input type="text" class="form-control" id="zipCode" formControlName="zipCode" placeholder="Zip/Postal Code">
            </div>
        </div>
        @if(attachmentTemplate && row.coDriverVisible){
            <ng-container *ngTemplateOutlet="attachmentTemplate;"></ng-container>
        }
        @if(row.tempCreateDriver) {

            <div class="form-caption">Security</div>
            <div class="row-2 pass_field">
    
                <div class="block-input-icon-with-err">
                    <input [type]="fieldTextType" class="form-control" id="password" formControlName="password" [placeholder]="row.tempCreateDriver ? 'Password*' : 'Password'" autocomplete="new-password">
                    <svg-icon src="../../../../../../../assets/svg/show-password.svg" (click)="togglePassType()"></svg-icon>
                    <div class="input-err" *ngIf="driverForm.get('password')?.invalid">
                        <div *ngIf="driverForm.get('password').errors?.['pattern']">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Password must contain one number, one uppercase and a
                            lowercase letter and 8 characters
                        </div>
                    </div>
                    <div class="input-err" *ngIf="driverForm.get('password')?.invalid">
                        <div *ngIf="driverForm.get('password')?.errors?.required">
                            <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"></svg-icon>
                            Passwords is required
                          </div>
                    </div>
    
                </div>
                <div class="block-input-icon-with-err">
                    <input [type]="fieldTextType" class="form-control" id="password_confirm" formControlName="password_confirm" placeholder="Password confirm" autocomplete="new-password">
                    <svg-icon src="../../../../../../../assets/svg/show-password.svg" (click)="togglePassType()"></svg-icon>
                    <div class="input-err" *ngIf="driverForm.get('password_confirm')?.invalid">
                        <div *ngIf="driverForm.get('password_confirm').errors?.['confirmedValidator']">
                            <svg-icon src="assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                            Passwords did not match
                        </div>
                    </div>
                </div>
            </div>
        }
    </form>
  </div>